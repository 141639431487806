import axios from "axios";
import config from "../config";

const API_URL = config.API_URL;

const getListPredios = () => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "catastro/list_predios")
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

const getFullDataPredio = (list_predios) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/full_predio/${list_predios}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getFullDataPredioConservacion = (list_predios) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/full_predio_conservation/${list_predios}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListUserReconocedor = () => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + "users/reconocedores").then((response) => {
      resolve(response);
      // console.log(response.data)
    });
  });
};

const getListUser = () => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "auth/users").then((response) => {
      resolve(response);
      // console.log(response.data)
    });
  });
};


export const getReconocedoresMysql = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `users/reconocedores_mysql`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

export const getAllUsersPredios = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `users_predios`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

export const getUserPredios = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `user_predios/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

export const postUsersPredios = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + "users_predios", data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListPrediosMySql = () => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + "predios-mysql")
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const postPrediosMySql = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + "predios-mysql", data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListCompleteDataUserPredios = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `complete_user_predios/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getBorradores = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `borradores`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getBorradoresAll = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `borradores_all`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getBorradoresConservation = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `borradores_conservation`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const updateEstadoBorradores = (id, estado, observaciones, npn) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(API_URL + `update_estado_borradores/${id}/${estado}/${npn}`, observaciones)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const updateEstadoBorradoresConservacion = (id, estado, observaciones, npn) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(API_URL + `update_estado_borradores_conservation/${id}/${estado}/${npn}`, observaciones)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const setFullDataPredio = (list_data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/set_full_predio`, list_data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListPredios,
  getFullDataPredio,
  getListUser,
};
