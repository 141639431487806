import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Table, Row } from '@themesberg/react-bootstrap';

import { Center } from "native-base";
import { Img } from './image';

export const FotosTable = ({ images, keyTable }) => {

  const [state, setState] = useState({ tableHead: ["Nombre", "Foto"], tableData: [],})
  const [validate, setValidate] = useState(false)

  useEffect(() => {

    let newTable = [];

    images.forEach(res => newTable.push([res.name, res.route]))

    const copyState = {...state}
    copyState.tableData = newTable

    setState(copyState)
    if(images.length !== 0) {
      setValidate(true)
    }

  }, [images, state]);

  const image_tab = (data) => (
    <Img data={data} style={ {width : 200, height: 200, resizeMode: "contain"} } bigStyle={ {height: 600, width: 600, resizeMode: "contain"} } className="hover-zoom" />
  );

  return !validate ? (
    <View style={styles.container}>
      <Center>
        <Text textAlign="center">Sin Imagenes</Text>
      </Center>
    </View>
  ) : (
    <>
      <View style={styles.container}>
        <Table borderStyle={{ borderWidth: 1, borderColor: "#C1C0B9" }}>
          <Row
            data={state.tableHead}
            style={styles.head}
            textStyle={styles.btnText}
          />
          {state.tableData.map((rowData, index) => (
            <th key={index} style={styles.row}>
              {rowData.map((cellData, cellIndex) =>
                cellIndex === 1 ? (
                  <th
                    key={cellIndex}
                    data={image_tab(cellData)}
                    textStyle={styles.image}
                    className="img-wrapper"
                  />
                ) : (
                  <th
                    key={cellIndex}
                    data={cellData}
                  />
                )
              )}
            </th>
          ))}
        </Table>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 4, backgroundColor: "#fff" },
  head: { height: 40, backgroundColor: "#808B97" },
  text: { margin: 6, textAlign: "center" },
  image: { textAlign: "center", padding: 20, marginLeft: 10 },
  row: { flexDirection: "row", backgroundColor: "#fff" },
  btn: {
    width: 58,
    height: 18,
    backgroundColor: "#f20909",
    borderRadius: 2,
    textAlign: "center",
  },
  btnText: { textAlign: "center", color: "#fff" },
});
