import React, {useState, useEffect} from 'react'
import {
  Text,
  View
} from 'native-base';
import { Table } from '@themesberg/react-bootstrap';
import { StyleSheet} from 'react-native';
import orderData from '../../../../helpers/orderData';

export const TerrenoTable = ({dataTerrenos}) => {

  const tableHead = ['Área de Terreno', 'Área Común', 'Área Total Terreno', 'Inicio Vida Útil', 'Fin Vida Útil'];
  const [terrenos, setTerrenos] = useState([]);

  useEffect(()=> {
      const dataHeaders = ["area_terreno", "area_comun", "area_total", "comienzo_vida_util", "fin_vida_util"];
      const dataTable = orderData(dataTerrenos, dataHeaders)
      setTerrenos(dataTable)
  }, [dataTerrenos])

  return (
    <View bg="white" >
      <Text mx={5} my={2}>Información de Terreno</Text>
      <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
        <th data={tableHead} style={styles.head} textStyle={styles.textHeader}/>
        {
          terrenos.map((rowData, index) => (
            <th key={index} style={styles.row}>
              {
                rowData.map((cellData, cellIndex) => (
                  <th key={cellIndex} data={cellData} textStyle={styles.textData}/>
                ))
              }
            </th>
          ))
        }
      </Table>
    </View>
  )
}


const styles = StyleSheet.create({
  head: { height: 40, backgroundColor: '#808B97' },
  textHeader: { margin: 6, color: '#fff', textAlign: "center" },
  textData: { margin: 6, textAlign: "center" },
  row: { flexDirection: 'row'},
});