import React from 'react'

import { ErrorMessage } from '@hookform/error-message';
import { Form } from '@themesberg/react-bootstrap';

export const InputText = ({label="", name, placeholder="", errors={}, type="text", register, required=false }) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control 
                type={type}
                isInvalid={errors[name]}
                placeholder={placeholder}
                {
                    ...register(name, { required: required ? `El campo ${label} es requerido` : false , pattern: type === 'email' ? {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Debe introducir un correo válido"
                        } : false  })
                } 
            />
            {
                errors[name] &&
                <Form.Control.Feedback type="invalid">
                    <ErrorMessage errors={errors} name={name} />
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
}

export const InputSelect = ({label="", name, placeholder="Seleccione", errors={}, register, required=false, options=[] }) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Select 
                isInvalid={errors[name]}
                {
                    ...register(name, { required: required ? `El campo ${label} es requerido` : false })
                } 
            >
                <option value="" >{placeholder}</option>
                {
                    options.map(option => (
                        <option value={option.id} key={option.id}> {option.name || option.nombre} </option>
                    ))
                }
            </Form.Select>
            {
                errors[name] &&
                <Form.Control.Feedback type="invalid">
                    <ErrorMessage errors={errors} name={name} />
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
}