import React, {useState, useEffect} from 'react'
import {
  View,
  Text
} from 'native-base';
import { Table, Row } from '@themesberg/react-bootstrap';
import { StyleSheet} from 'react-native';
import orderData from '../../../../helpers/orderData';
import { RowConstrucciones } from './RowConstrucciones';

export const ConstruccionTable = ({dataConstrucciones, construccionesTipo, selects}) => {
  const tableHead = ['Id unidad', 'Tipo de construcción', 'Tipo unidad', 'Destino económico', 'Área construcción', 'Inicio Vida Útil', 'Fin Vida Útil', 'Puntaje total', 'Número pisos', 'Año construcción', 'Número baños', 'Número locales', 'Planta ubicación', 'Altura', 'Calificaciones'];

  const [construcciones, setConstrucciones] = useState([]);
  
  useEffect(()=> {
      const dataHeaders = ["id_unidad", "tipo_construccion", "tipo_unidad", "destino", "area_construida", "comienzo_vida_util", "fin_vida_util", "total_puntaje", "total_pisos", "anio_construccion", "total_banos", "total_locales", "planta_ubicacion", "altura", "btn"];
      const newData = dataConstrucciones.map((da) => ({...da, btn: 1, total_puntaje: da.calificacion !== undefined ? da.total_puntaje : 'Null'}))
      const dataTable = orderData(newData, dataHeaders)
      setConstrucciones(dataTable)
  }, [dataConstrucciones])

  return (
    <View bg="white" >
      <Text mx={5} my={2}>Información de Construcción</Text>
      <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
        <Row data={tableHead} style={styles.head} textStyle={styles.textHeader}/>
        {
          construcciones.map((rowData, index) => (
            <RowConstrucciones 
              key={`Fila de construccion ${index}`}
              index={index}
              rowData={rowData}
              data={dataConstrucciones[index]}
              construccionesTipo={construccionesTipo}
              selects={selects}
            />
          ))
        }
      </Table>
    </View>
  )
}


const styles = StyleSheet.create({
  head: { height: 40, backgroundColor: '#808B97' },
  textHeader: { margin: 6, color: '#fff', textAlign: "center" },
  textData: { margin: 6, textAlign: "center" },
  row: { flexDirection: 'row'}
});