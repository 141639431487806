export const renderStatus = (status) => {
    switch (status) {
        case "0":
        case 0:
            return "Enviado"
        case "1":
        case 1:
            return "Aceptado"
        case "2":
        case 2:
            return "Rechazado"
        case "3":
        case 3:
            return "Descargado"
        case "4":
        case 4:
            return "Backup"
        default:
            return "Sin Estado"
    }
}