import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Card, Button} from '@themesberg/react-bootstrap';
import Service from '../../services/catastro.service';
import DataTable from '../components/DataTable';
import ButtonsActions from './components/actions/ButtonsActions';
import ModalUser from './components/users/ModalUser';
import Swal from 'sweetalert2';

const Usuarios = () => {

    const tHead = [
        { Header: 'Usuario', accessor: 'username', disableFilters: true },
        { Header: 'Correo', accessor: 'email', disableFilters: true },
        { Header: 'Rol', accessor: 'role', disableFilters: true },
        { Header: 'Grupo', accessor: 'group', disableFilters: true },
        { Header: 'Acciones', accessor: 'actions', disableFilters: true, disableSortBy: true }
    ];
      
    // const tBody = [
    //     { username: 'Jack', email: "pepe@gmail.com" },
    //     { username: 'Sam', email: "pepe@gmail.com" }
    // ];


    const [listUsers, setListUsers] = useState([])
    const [bodyTable, setBodyTable] = useState([])
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [listRoles, setListRoles] = useState([])
    const [listGroups, setListGroups] = useState([])

    const handleEdit = (object) => {
        setDataModal(object)
        setIsEdit(true)
        setShowModal(true)
    }

    const handleNew = () => {
        setDataModal({})
        setIsEdit(false)
        setShowModal(true)
    }

    const handleRecoveryPassword = async (object) => {
        Swal.fire({
            title: `Define la nueva contraseña para el usuario ${object.username}`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Restablecer',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async (password) => {
                let response = null
                try {
                    response = await Service.updatePasswordUserAdmin(object.id, {password})
                    return response
                } catch (error) {
                    Swal.showValidationMessage(
                        `Error en la solicitud: ${error}`
                    )
                    return error
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed && result.value.status === 200) {
                Swal.fire(`Se ha restablecido la contraseña al usuario ${object.username}`, '', 'success')
            }
        })
    }

    const handleDelete = async (object) => {
        Swal.fire({
            title: `Está seguro de eliminar al usuario ${object.username}?`,
            showCancelButton: true,
            confirmButtonText: 'Estoy Seguro',
            cancelButtonText: `Cancelar`,
        }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                let response = null
                response = await Service.deleteUserAdmin(object.id)
                Swal.fire(`El usuario ${object.username} ha sido eliminado satisfactorialmente`, '', 'success')
                setLoading(!loading)
            } catch (error) {
                Swal.fire('Ocurrió un error!', '', 'error')
            }
        }
        })
    }

    useEffect(() => {
        const getData = async () => {
            try {
                let roles = await Service.getListRoles()
                setListRoles(roles.data.data)
                let grupos = await Service.getListGrupos()
                setListGroups(grupos.data)
            } catch (error) {
                setListRoles([])
                setListGroups([])
            }
            
        }
        getData()
    }, [])

    useEffect(() => {

        const renderButtons = (object) => {
            return (
                <ButtonsActions object={object} handleDelete={handleDelete} handleEdit={handleEdit} handleRecoveryPassword={handleRecoveryPassword} />
            )
        }

        const getData = async () => {
            try {
                const data = await Service.getListUserAdmin()
                console.log(data.data)
                if (data.status === 200) {
                    let tableBody = []
                    data.data.forEach(user => {
                        tableBody.push({
                            username: user.username,
                            email: user.email,
                            role: user.roles.length > 0 ? user.roles[0].name : '',
                            group: user.grupos.length > 0 ? user.grupos[0].nombre : '',
                            actions: renderButtons(user)
                        })
                    });
                    setListUsers(data.data)
                    setBodyTable([...tableBody])
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        }

        getData()
    }, [loading])

    return (
        <>
        {showModal && <ModalUser setShowModal={setShowModal} showModal={showModal} dataModal={dataModal} isEdit={isEdit} loading={loading} setLoading={setLoading} listRoles={listRoles} listGroups={listGroups} />}
        <Container className="px-0">
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Header>
                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                <div>
                                    <h2 className="h2" >Usuarios</h2>
                                </div>
                                <div>
                                    <Button variant='primary' size='sm' onClick={handleNew} >
                                        Nuevo Usuario
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {!loading && <DataTable tHead={tHead} tBody={bodyTable} />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Usuarios
