import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
//import Login from "./pages/others/Login";
import ScrollToTop from "./components/ScrollToTop";
import { BorradoresProvider } from './context/borradores/BorradoresContext';

ReactDOM.render(
  <HashRouter>
    <ScrollToTop />
    <BorradoresProvider>
      <HomePage />
    </BorradoresProvider>
  </HashRouter>,
  document.getElementById("root")
);
