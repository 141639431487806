import { Card, Col, Container, Row } from '@themesberg/react-bootstrap';
import DataTableBorradores, { ColumnFilter } from '../../../components/DataTableBorradores';
import FilterReconocedores from './FilterReconocedores';
import FilterStatus from './FilterStatus';
import { useBorradores } from '../../../../context/borradores/BorradoresContext';
import { Busqueda } from './Busqueda';
import CityStatus from './cityStatus';
import { useEffect } from 'react';

const Borradores = () => {
    const { tBody, tBodySearched, modoBusqueda, limpiarBusqueda, buscarDatosBorradores, valoresBusqueda, listaBorradores, loading, sizePage } = useBorradores();

    const tHead = [
        { Header: 'ID Predio', accessor: 'id', canFilter: false, Filter: ColumnFilter },
        { Header: 'Municipio', accessor: 'city', canFilter: false, disableSortBy: true, Filter: CityStatus },
        { Header: 'NPN', accessor: 'npn', canFilter: false, Filter: ColumnFilter },
        { Header: 'Estado', accessor: 'status', canFilter: false, disableSortBy: true, Filter: FilterStatus },
        { Header: 'Reconocedor', accessor: 'user', canFilter: false, disableSortBy: true, Filter: FilterReconocedores },
        { Header: 'Acciones', accessor: 'actions', disableFilters: true, disableSortBy: true }
    ];

    useEffect(() => {
        const getData = async () => {

            limpiarBusqueda()
            await buscarDatosBorradores({ ...valoresBusqueda }, 1, sizePage, listaBorradores)
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    //console.log("Andiamo",tBodySearched);
   

    return (
        <>
            <Container className="px-0">
                <Row>
                    <Col xs={12} className="p-3">
                        <Card>
                            <Card.Header>
                                <div className='d-flex justify-content-start'>
                                    <div>
                                        <h2 className="h2 text-center" >Borradores</h2>
                                    </div>
                                </div>
                                <Busqueda />
                            </Card.Header>
                            <Card.Body>
                                {!loading && !modoBusqueda && (<div>
                                    <h6 className="h6 text-center" >Busquedas Recientes</h6>
                                </div>)}
                                {!loading && modoBusqueda && (<div>
                                    <h6 className="h6 text-center" >Datos Encontrados</h6>
                                </div>)}
                                {!loading && <DataTableBorradores
                                    tHead={tHead}
                                    tBody={modoBusqueda ? tBodySearched : tBody}
                                />}
                                {loading && <h3 className="text-center">Cargando...</h3>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Borradores;
