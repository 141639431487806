import React from "react";
import { StyleSheet } from "react-native";
import { Row } from '@themesberg/react-bootstrap';
import { Text, NativeBaseProvider, Center } from "native-base";


export const RowBorrador =({rowData, index, getResumen}) => {

    const row = (data) => {
        return [
            data.data_predio.general[0].id_predio,
            data.data_predio.general[0].npn,
            data.username,
            element(data.data_predio.general[0].npn)
        ]
    }

    const showModal = () => {
        getResumen(rowData, index)
    }

    const element = () => (
        <Text style={styles.btn} onPress={() => showModal()}>Resumen</Text>
    );

    // const submit = async(estado) => {
    //     updateEstadoBorradores(rowData.id, estado, {observaciones: rowData.observaciones}, rowData.data_predio.general[0].npn).then(
    //         (response) => {
    //             console.log('=====>>>>>>>>', rowData.data_predio)
    //             setFullDataPredio(rowData.data_predio).then(
    //                 (res) => {
    //                     showToastVerified(res.data.data)
    //                 }
    //             );
    //             setModal(false)
    //             deletePredioVerified(index)
    //         },
    //         (error) => {
    //             console.log(error)
    //             setModal(false)
    //             showToastRejected()
    //             deletePredioVerified(index)
    //         }
    //     )
    // }

    return (
        <>
            <NativeBaseProvider>
                <Center>
                    <Row
                    key={index}
                    data={row(rowData)}
                    flexArr={[1, 2, 1, 1]}
                    // eslint-disable-next-line no-sequences
                    style={styles.row, {flex: 1, borderWidth: 1, borderColor: "#c8e1ff"}}
                    textStyle={styles.textData}
                    />
                    {/* <Modal isOpen={modal} onClose={() => setModal(false)}>
                        <Modal.Content maxWidth="90%" maxHeigth="90%">
                            <Modal.CloseButton />
                            <Modal.Header>Resumen</Modal.Header>
                            <Modal.Body>
                                <ResumenBorrador data={rowData} index={index} handleChangeObservations={handleChangeObservations} construccionesTipo={construccionesTipo} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button.Group variant="ghost" space={2}>
                                    <Button onPress={() => submit(2)}>Rechazar</Button>
                                    <Button onPress={() => submit(1)}>Aceptar</Button>
                                </Button.Group>
                            </Modal.Footer>
                        </Modal.Content>
                    </Modal> */}
                </Center>
            </NativeBaseProvider >
        </>
    )
}

const styles = StyleSheet.create({
  textData: { margin: 6, textAlign: "center", textTransform: "capitalize" },
  row: { flexDirection: "row" },
  btn: { textAlign: "center", backgroundColor: "#78B7BB", padding: 1, width: "80%", borderRadius: 4, marginLeft: "10%", color: "#fff" },
});