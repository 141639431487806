import axios from "axios";
import config from "../config";


const API_URL = config.API_URL;


const getListPredios = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + "catastro/list_predios")
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getFullDataPredio = (list_predios) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/full_predio/${list_predios}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const setFullDataPredio = (list_data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/set_full_predio`, list_data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const uploadImages = (list_images) => {
  console.log(list_images);
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/multipleUpload`, {
        method: "POST",
        headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";'
        },
        body: list_images,
			})
      .then((response) => {
        if ( 200 === response.status ) {
          // If file size is larger than expected.
          if( response.data.error ) {
            if ( 'LIMIT_FILE_SIZE' === response.data.error.code ) {
              console.log( 'Max size: 2MB', 'red' );
            } else if ( 'LIMIT_UNEXPECTED_FILE' === response.data.error.code ){
              console.log( 'Max 4 images allowed', 'red' );
            } else {
              // If not the given ile type
              console.log( response.data.error, 'red' );
            }
          } else {
            // Success
            let fileName = response.data;
            console.log( 'fileName', fileName );
          }
        }
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// const getListUser = () => {
//   return new Promise(function (resolve, reject) {
//     axios
//       .post("auth/users")
//       .then((data) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

const getListUser = () => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "auth/users").then((response) => {
      resolve(response);
      // console.log(response.data)
    });
  });
};

const getRegistroUser = (dataUSer) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "auth/signup", dataUSer).then((response) => {
      resolve(response.data);
      // console.log(response.message);
    }).catch((error) => {
      reject(error)
    });
  });
};

const updateUser = (dataUpdate, grupoUsuarios) => {
  console.log("update", grupoUsuarios);
  return new Promise(function (resolve, reject) {
    axios
      .post(
        API_URL + "auth/updateUser/" + dataUpdate,
        grupoUsuarios
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getListUserAdmin = () => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + "auth/list_users_admin")
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error)
    });
  });
};

const registerUserAdmin = (dataUSer) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "auth/registerUser", dataUSer).then((response) => {
      resolve(response);
      // console.log(response.message);
    }).catch((error) => {
      console.log('error', error)
      reject(error)
    });
  });
};


const updateUserAdmin = (idUser, dataUser) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        API_URL + "auth/updateUserAdmin/" + idUser,
        dataUser
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updatePasswordUserAdmin = (idUser, dataUser) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        API_URL + "auth/updateUserPasswordAdmin/" + idUser,
        dataUser
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteUserAdmin = (idUser) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        API_URL + "auth/deleteUserAdmin/" + idUser
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getListGrupos = () => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + "auth/grupos").then((response) => {
      resolve(response);
      // console.log(response.data)
    });
  });
};

const getListRoles = () => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + "auth/roles")
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error)
    });
  });
};

const createGrupoUser = (dataGrupo, grupoUsuarios) => {
  // console.log("update", grupoUsuarios);
  return new Promise(function (resolve, reject) {
    axios
      .post(
        API_URL + "auth/creategrupouser/" + grupoUsuarios,
        dataGrupo
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListPredios,
  setFullDataPredio,
  getFullDataPredio,
  getListUser,
  getRegistroUser,
  updateUser,
  uploadImages,
  getListGrupos,
  createGrupoUser,
  updateUserAdmin,
  deleteUserAdmin,
  registerUserAdmin,
  updatePasswordUserAdmin,
  getListRoles,
  getListUserAdmin
};
