import { Col, Form, Row } from '@themesberg/react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useBorradores } from '../../../../context/borradores/BorradoresContext';
import { getReconocedoresMysql } from '../../../../services/catastro_m2.service';

const FilterReconocedores = ({ column }) => {
    const { filterValue, setFilter } = column
    const { reconocedores: recon } = useBorradores()
    const [reconocedores, setReconocedores] = useState([])

    useEffect(() => {

        setReconocedores([...recon])

    }, [recon])


    return (
        <Row>
            <Col xs={12}>
                <Form.Select
                    value={filterValue || ""}
                    onChange={e => setFilter(e.target.value)}
                    size='sm'
                >
                    <option value="">Todos</option>
                    {reconocedores.map((reconocedor, index) => (
                        <option key={reconocedor.name + index} value={reconocedor.name}>{reconocedor.name}</option>
                    ))
                    }
                </Form.Select>
            </Col>
        </Row>
    )
};

export default React.memo(FilterReconocedores);
