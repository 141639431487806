import axios from "axios";
import config from "../config";

const API_URL = config.API_URL;


const getInteresadosPredios = (predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/interesados_predio/${predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
 
};
const getTerrenosPredio = (predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/terreno_predio/${predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getConstruccionesPredio = (predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/unidades_construccion/${predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getDataPredio = (predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/data_predio/${predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getContactosPredio = (predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/contactos_predio/${predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getInteresado = (numero_documento) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/consulta_documento/${numero_documento}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getInteresadoSecuencial = (secuencial) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/interesado/${secuencial}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSelects = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/general_dominios`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postInteresado = (interesado) => {
  console.log("interesado", interesado);
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL +"catastro/insertInteresado", interesado)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateInteresado = (interesado, secuencial) => {
  // console.log('interesado', interesado)
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL +`catastro/update_interesado/${secuencial}`, interesado)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const construccionesPredio = (id_predio) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/construcciones/construcciones_predio/${id_predio}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const construccion = (id) => {
  console.log("construccion");
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/construcciones/construccion/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const calificacion_Construccion = (id) => {
  console.log("construccion");
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/construcciones/calificacion_construccion/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const clase_calificacion_Construccion = (id) => {
  console.log("construccion");
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/construcciones/clase_calificacion_construccion/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const selects_construccion = () => {
  console.log("selects_construccion");
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL +`catastro/construcciones/selects_construccion`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateInteresadoPredio = (body, id_predio) => {

  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL +`catastro/update_interesado_predio/${id_predio}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const insertInteresadoPredio = (body) => {

  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL +`catastro/insert_interesado_predio`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const consultaRelacionIntPre = (id_predio, secuencial) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        API_URL +`catastro/consulta_relacion_intpre/${id_predio}/${secuencial}`
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getInteresadosPredios,
  getTerrenosPredio,
  getConstruccionesPredio,
  getDataPredio,
  getContactosPredio,
  getInteresado,
  getInteresadoSecuencial,
  getSelects,
  postInteresado,
  construccionesPredio,
  construccion,
  clase_calificacion_Construccion,
  calificacion_Construccion,
  selects_construccion,
  updateInteresado,
  updateInteresadoPredio,
  consultaRelacionIntPre,
  insertInteresadoPredio,

  
};