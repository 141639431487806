import React from 'react'
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import { InputSelect, InputText } from '../forms/InputsForms';
import Swal from 'sweetalert2'
import Service from '../../../../services/catastro.service'


const ModalUser = ({showModal, setShowModal, dataModal, isEdit, loading, setLoading, listRoles, listGroups}) => { 
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: isEdit ? 
        {
            email: dataModal.email,
            username: dataModal.username,
            roles: dataModal.roles.length > 0 ? dataModal.roles[0].id : '',
            groups: dataModal.grupos.length > 0 ?  dataModal.grupos[0].id : '',
        } : 
        {
            email: '',
            username: '',
            password: '',
            roles: '',
            groups: ''
        }
    });

    const handleClose = () => {
        setShowModal(false)
    }

    const handleSave = async (data) => {
        Swal.fire('Por favor espere')
        Swal.showLoading()
        let response = null;
        data.roles = [data.roles]
        try {
            if (!isEdit) {
                response = await Service.registerUserAdmin(data)
            }else {
                response = await Service.updateUserAdmin(dataModal.id, data)
            }
            if (response.status === 200) {
                Swal.hideLoading()
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500
                })
                reset()
                setShowModal(false)
                setLoading(!loading)
            }else {
                Swal.hideLoading()
                Swal.fire({
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500
                })
            }
            
            
        } catch (error) {
            Swal.hideLoading()
            Swal.fire({
                icon: 'error',
                title: "Hubo un error en la solicitud",
                showConfirmButton: false,
                timer: 2500
            })
        }
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
            <Form onSubmit={handleSubmit(handleSave)}>
                <Modal.Header>
                    <Modal.Title className="h6">{isEdit ? 'Actualizar Usuario' : 'Nuevo Usuario'}</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <InputText 
                        label= "Nombre de Usuario"
                        name="username"
                        placeholder='Usuario'
                        errors={errors}
                        register={register}
                        required
                    />
                    <InputText 
                        label= "Email"
                        name="email"
                        placeholder='Email'
                        errors={errors}
                        register={register}
                        required
                        type='email'
                    />
                    {
                        !isEdit &&
                        <InputText 
                            label= "Contraseña"
                            name="password"
                            placeholder='Contraseña'
                            errors={errors}
                            register={register}
                            required
                            type='password'
                        />
                    }
                    <InputSelect 
                        label= "Rol"
                        name="roles"
                        placeholder='Seleccione un Rol'
                        errors={errors}
                        register={register}
                        required
                        options={listRoles}
                    />
                    <InputSelect 
                        label= "Grupos"
                        name="groups"
                        placeholder='Seleccione un Grupo'
                        errors={errors}
                        register={register}
                        required
                        options={listGroups}
                    />
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type='submit' >
                        {isEdit ? 'Actualizar' : 'Guardar'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModalUser
