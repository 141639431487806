import { Button, Col, Form, Row } from "@themesberg/react-bootstrap"
import { useEffect } from "react";
import { useState } from "react";
import { useBorradores } from "../../../../context/borradores/BorradoresContext";

export const Busqueda = () => {
    const { reconocedores: recon, buscarDatosBorradores, limpiarBusqueda, modoBusqueda: Busqueda, loading, gruposSize, setGruposSize, valoresBusqueda, setValoresBusqueda, listaBorradores, setListaBorradores, sizePage, setSizePage } = useBorradores()
    const [reconocedores, setReconocedores] = useState([])

    const handleChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        if (name === "listaBorradores") {
            setListaBorradores(value)
        }
        else if (name === "sizePage" && /^\d+$/.test(value)) {
            setSizePage(parseInt(value))
        }
        else if (name === "sizePage" && value === "") {
            setSizePage(0)
        }
        else {
            setValoresBusqueda({ ...valoresBusqueda, [name]: value })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const { name } = e.target

        if (Busqueda && name === "browserForm") {
            setValoresBusqueda({
                "id_predio": "",
                "npn": "",
                "estado": "",
                "user": ""
            })

            setGruposSize({
                sizeSearch: 0,
                groups: 0,
                group: 0
            })

            setSizePage(20)

            setListaBorradores("actualizacion")
            limpiarBusqueda()
        }
        else if (Busqueda && name === "groupsForm") {
            limpiarBusqueda()
            await buscarDatosBorradores({ ...valoresBusqueda, totalResultados: gruposSize.sizeSearch, maxPages: gruposSize.groups }, gruposSize.group + 1, sizePage, listaBorradores)
        } else {
            await buscarDatosBorradores({ ...valoresBusqueda }, 1, sizePage, listaBorradores)
        }
    }

    useEffect(() => {
        setReconocedores([...recon])
    }, [recon])

    return (
        <div>
            <Row>
                <Form
                    xs="12"
                    className='d-flex justify-content-between'
                    name="browserForm"
                    onSubmit={e => handleSubmit(e)}>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase fs-7 fw-bolder">id predio</span>
                        <Form.Control
                            name="id_predio"
                            type="text"
                            value={valoresBusqueda["id_predio"]}
                            onChange={(e) => handleChange(e)}
                            placeholder="Buscar..."
                            size="sm"
                            disabled={Busqueda}
                        />
                    </Col>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase fs-7 fw-bolder">npn</span>
                        <Form.Control
                            name="npn"
                            type="text"
                            value={valoresBusqueda["npn"]}
                            onChange={(e) => handleChange(e)}
                            placeholder="Buscar..."
                            size="sm"
                            disabled={Busqueda}
                        />
                    </Col>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase fs-7 fw-bolder">estado</span>
                        <Form.Select
                            name="estado"
                            value={valoresBusqueda["estado"]}
                            onChange={(e) => handleChange(e)}
                            size='sm'
                            disabled={Busqueda}
                        >
                            <option value="Enviado">Enviado</option>
                            <option value="Aceptado">Aceptado</option>
                            <option value="Rechazado">Rechazado</option>
                            <option value="Descargado">Descargado</option>
                            <option value="Backup">Backup</option>
                            <option value="">Todos</option>
                        </Form.Select>
                    </Col>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase fs-7 fw-bolder">reconocedor</span>
                        <Form.Select
                            name="user"
                            value={valoresBusqueda["user"]}
                            onChange={(e) => handleChange(e)}
                            size='sm'
                            disabled={Busqueda}
                        >
                            <option value="">Todos</option>
                            {
                                reconocedores.map((reconocedor, index) => (
                                    <option key={reconocedor.name + index} value={reconocedor.name}>{reconocedor.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase text-nowrap fs-7 fw-bolder"><small>lista borradores</small></span>
                        <Form.Select
                            name="listaBorradores"
                            value={listaBorradores}
                            onChange={(e) => handleChange(e)}
                            size='sm'
                            disabled={Busqueda}
                        >
                            <option key="actualizacion" value="actualizacion">
                                Actualización
                            </option>
                            <option key="conservacion" value="conservacion">
                                Conservación
                            </option>
                        </Form.Select>
                    </Col>
                    <Col className="mx-1">
                        <span className="text-center text-uppercase fs-7 text-nowrap fw-bolder"><small>Tamaño grupo</small></span>
                        <Form.Control
                            name="sizePage"
                            type="text"
                            value={sizePage}
                            onChange={(e) => handleChange(e)}
                            size="sm"
                            disabled={Busqueda}
                        />
                    </Col>
                    <Col className="mx-1 d-flex flex-column">
                        <span className="text-center text-uppercase fs-7 fw-bolder">acción</span>
                        <Button
                            type='submit'
                            value={Busqueda ? "limpiar" : "buscar"}
                            children={Busqueda ? "Nueva Busqueda" : "Buscar"}
                            size="sm"
                            disabled={loading}
                        />
                    </Col>


                </Form>
            </Row>
            <div className="mt-1" hidden={!gruposSize.groups}>
                <Form
                    xs="12"
                    className='d-flex justify-content-around'
                    name="groupsForm"
                    onSubmit={e => handleSubmit(e)}>
                    <div className="d-flex justify-content-center">
                        <div className="me-2">
                            <p>Total Resultados:  {gruposSize.sizeSearch}&nbsp;|</p>
                        </div>
                        <div className="me-2">
                            <p >Grupo <strong>{gruposSize.group}</strong> de <strong>{gruposSize.groups}</strong>&nbsp;|</p>
                        </div>
                        <div>
                            <button
                                type='submit'
                                value={gruposSize.group + 1}
                                children={`Ir al grupo ${gruposSize.group + 1}`}
                                className="btn btn-outline-dark btn-sm py-0 my-0"
                                hidden={!(gruposSize.group < gruposSize.groups)}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </div>

    )
}
