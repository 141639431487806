import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
// import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
//import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import AuthService from "../../services/auth.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const [userData, setUserData] = useState({ username: "", password: "" });
  const [btns, setBtns] = useState({ username: false, password: false });
  const [errorMessage, setErrorMessage] = useState({ value: "" });

  const history = useHistory();

  ////console.log("auth", localStorage.getItem("isAuthenticated"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

  };

  const handleInputOnKeyUp = (e) => {
    const { name, value } = e.target;

    setBtns((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    //if username or password field is empty, return error message
    if (userData.username === "" || userData.password === "") {
      setErrorMessage((prevState) => ({
        type: 1, value: "Campo de usuario o contraseña vacío",
      }));
      return;
    }

    setErrorMessage((prevState) => ({ type: 3, value: "Loading" }));

    AuthService.login(userData).then(
      async (response) => {
        ////console.log(response);

          // if (localStorage.getItem("user") !== null) {
          //     const user = {
          //     profile: {},
          //     predios: {},
          //     subidas: {},
          //     };
      
          //     let final_user = JSON.stringify(user);
      
          //     localStorage.setItem("user", btoa(final_user));
          // }

          // localStorage.setItem("user_data", btoa(JSON.stringify(response)));

          // //Guardo dominios fuente
          // let dominiosFuente = {}
          // const dataCiudades = await getCiudadesList()
          // const dataEntes = await getEntesEmisor()
          // const dataEstados = await getEstadoDisponibilidad()
          // const dataAdquisiciones = await getAdquisicion()
          // const dataFuente = await getFuenteTipo()

          // dominiosFuente = {
          //     dataCiudades: dataCiudades?.data.data,
          //     dataEntes: dataEntes?.data.data,
          //     dataEstados: dataEstados?.data.data,
          //     dataAdquisiciones: dataAdquisiciones?.data.data,
          //     dataFuente: dataFuente?.data.data
          // }

          // localStorage.storeData("dominios_fuente", JSON.stringify(dominiosFuente)).then(async () => {});

          // //Dominios interesados
          // let response_select = await catastro_jService.getSelects();
          // localStorage.storeData("dominios_interesados_f", JSON.stringify(response_select.data.data[0])).then(async () => {});

          // CatastroService.getDominiosGenerales().then(
          //     (response) => {
          //       const dataApiGeneral = response.data.data[0];
          //       localStorage.storeData(
          //         "dominios_generales",
          //         JSON.stringify(dataApiGeneral)
          //       ).then(async (res) => {});
          //     },
          //     (error) => {
          //       const _content =
          //         (error.response && error.response.data) ||
          //         error.message ||
          //         error.toString();
      
          //       //console.log(_content);
          //     }
          //   );

          //   catastro_jService.getSelects().then((response) => {
          //     const dataApiSelects = response.data.data[0];
          //     localStorage.storeData("selects", JSON.stringify(dataApiSelects)).then(
          //       async (res) => {}
          //     );
          //   });

          //   catastro_jService.selects_construccion().then((response) => {
          //     const dataApiSelects = response.data.data[0];
          //     localStorage.storeData(
          //       "selects_construccion",
          //       JSON.stringify(dataApiSelects)
          //     ).then(async (res) => {});
          //   });

          //   selects_calificacion().then((response) => {
          //     const dataApiSelects = response.data.data[0];
          //   // //console.log(dataApiSelects);
          //     localStorage.storeData(
          //       "selects_calificacion_clase",
          //       JSON.stringify(dataApiSelects)
          //     ).then(async (res) => {});
          //   });
          //   selects_conservacion().then((response) => {
          //     const dataApiSelects = response.data.data;
          //     ////console.log("La respuestaaaaa",response);
          //     localStorage.storeData(
          //       "selects_calificacion_convensional",
          //       JSON.stringify(dataApiSelects)
          //     ).then(async (res) => {});
          //   });
          //  selects_calif_correcta().then((response) => {
          //     const dataApiSelects = response.data.data;
          //     localStorage.storeData(
          //       "selects_calificacion_correcta",
          //       JSON.stringify(dataApiSelects)
          //     ).then(async (res) => {});
          //   });

          //console.log(response);

          for( var i in response.roles ) {
            if( response.roles[i] === "ROLE_ADMIN" ) {
              setUserData(response);
        
              let ReUsername = response.username.toLowerCase();
              ReUsername = ReUsername.charAt(0).toUpperCase() + ReUsername.slice(1);
              setTimeout(function(){
                setErrorMessage((prevState) => ({ type:2, value:"Inicio de sesión correcto! Bienvenido "+ReUsername }));
              },1000)
              setTimeout(function(){
                localStorage.setItem("isAuthenticated", "true");
                history.push("/dashboard/inicio");
              },2000)
              break;
            } else {
              setErrorMessage((prevState) => ({ type:1, value: "Solo se permiten usuarios administradores" }));
            }
          }
      },
      (error) => {
          setErrorMessage((prevState) => ({ type:1, value: error.response.data.message }));
      }
    );
  };

  //console.log(btns.username);

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Iniciar Sesión</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Usuario o correo</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required className={btns.username !== false ? btns.username === "" ? "is-invalid" : "is-valid" : ""} type="email" id="username" name="username" placeholder="Ejemplo: ejemplo@google.com" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      {/* <Form.Control autoFocus required isValid={btns.username !== "" ? (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(btns.username) ? true :false : false} isInvalid={btns.username === "" ? true : (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(btns.username) ? false :true} type="email" id="username" name="username" placeholder="Ejemplo: ejemplo@google.com" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} /> */}
                      <Form.Control.Feedback type="invalid">Por favor, digite el usuario.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required className={btns.password !== false ? btns.password === "" ? "is-invalid" : "is-valid" : ""} type="password" id="password" name="password" placeholder="Contraseña" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                        <Form.Control.Feedback type="invalid">Por favor, digite la contraseña.</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/* <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Recuerdame</FormCheck.Label>
                      </Form.Check> */}
                      <Card.Link as={Link} to={Routes.OlvidoContrasena.path} className="fw-bold">Olvidó su contraseña?</Card.Link>
                    </div>
                  </Form.Group>

                  {errorMessage.value && ( errorMessage.type === 1 ?
                    <div style={{textAlign:'center'}}><p className="text-danger"> {errorMessage.value} </p></div> : errorMessage.type === 2 ?
                    <div style={{textAlign:'center'}}><p className="text-success"> {errorMessage.value} </p></div> : <div style={{textAlign:'center', marginBottom: 20}}><Spinner animation="border" size="sm" /></div>
                  ) }
                  <Button variant="primary" type="submit" className="w-100">
                    Iniciar Sesión
                  </Button>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    No te has registrado?
                    <Card.Link as={Link} to={Routes.Registro.path} className="fw-bold">
                      {` Regístrate `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
