import { Card, Table } from '@themesberg/react-bootstrap';
import React from 'react';

const ContactoVisita = ({data}) => {
  return (
        <Card>
            <Card.Body>
                <Card.Title>Información de Contacto Visita</Card.Title>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Tipo de Id</th>
                            <th># de Id</th>
                            <th>Nombres</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.data_predio.contactos.map((contacto, index) => (
                                <tr key={index}>
                                    <td>{contacto.des_tipodoc}</td>
                                    <td>{contacto.num_doc_atendio}</td>
                                    <td>{contacto.nombre}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
  );
};

export default ContactoVisita;
