import React from 'react'
import { faPen, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';

const ButtonsActions = ({object, handleEdit, handleDelete, handleRecoveryPassword}) => {

    return (
        <>
            <Button variant='warning' size='xs' onClick={() => {handleEdit(object)}} >
                <FontAwesomeIcon icon={faPen} />
            </Button>

            <Button variant='danger' size='xs' className="mx-2" onClick={() => {handleDelete(object)}} >
                <FontAwesomeIcon icon={faTrash} />
            </Button>

            <Button variant='primary' size='xs' onClick={() => {handleRecoveryPassword(object)}} >
                <FontAwesomeIcon icon={faUnlock} />
            </Button>
        </>

    )
}

export default ButtonsActions
