import { Col, Form, Row } from '@themesberg/react-bootstrap';
import React from 'react';

const FilterStatus = ({column}) => {
    const {filterValue, setFilter} = column

    return (
        <Row>
            <Col xs={12}>
                <Form.Select
                    value={filterValue || ""}
                    onChange={e => setFilter(e.target.value)}
                    size='sm'
                >
                    <option value="Enviado">Enviado</option>
                    <option value="Aceptado">Aceptado</option>
                    <option value="Rechazado">Rechazado</option>
                    <option value="Descargado">Descargado</option>
                    <option value="Backup">Backup</option>
                    <option value="">Todos</option>
                </Form.Select>
            </Col>
        </Row>
    );
};

export default FilterStatus;
