import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { getReconocedoresMysql } from "../../services/catastro_m2.service";
import { getBorradores } from "../../utils/context/borradores/getBorradores";
import { bodyParamsQuery } from "../../utils/context/borradores/bodyParamsQuery_BuscarDatosBorradores";
import { useHistory } from "react-router-dom";
import { getBorrador } from "../../utils/context/borradores/getBorrador";
import { Routes } from "../../routes";
import { renderStatus } from "../../utils/context/borradores/renderStatus";

export const borradoresContext = createContext();

export const useBorradores = () => {
    return useContext(borradoresContext);
}
export const BorradoresProvider = ({ children }) => {

    const [tBody, setTBody] = useState({})
    const [tBodySearched, setTBodySearched] = useState({})
    const [modoBusqueda, setModoBusqueda] = useState(false)
    const [reconocedores, setReconocedores] = useState([]);
    const [municipios, setMunicipios] = useState([])
    const [predios, setPredios] = useState({})
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const [gruposSize, setGruposSize] = useState({
        sizeSearch: 0,
        groups: 0,
        group: 1
    })
    const [valoresBusqueda, setValoresBusqueda] = useState({
        "id_predio": "",
        "npn": "",
        "estado": "Enviado",
        "user": ""
    })
    const [listaBorradores, setListaBorradores] = useState("actualizacion")
    const [sizePage, setSizePage] = useState(20)

    const buscarDatosShowBorrador = async (idBorrador, listaBorrador) => {
        // Devuelve data_predio y observaciones

        const filtroParams = {
            "id": idBorrador,
            "borradoresParams": {
                "data_predio": [
                    "full"
                ],
                "BorradorGeneral": [
                    "observaciones",
                    "id",
                    "user_id",
                    "estado"
                ]
            }
        }

        const [bodyParams, query] = bodyParamsQuery(filtroParams, 0, 0, listaBorrador)

        const borrador = await getBorrador(bodyParams, query)

        return borrador

    }

    const buscarDatosBorradores = async (filtroParams, page = 1, sizePage = 0, listaBorradores = "") => {
        // Devuelve los datos basicos (id_predio,npn,estado,username) para mostrar 
        // en la sección de borradores según el filtro utilizado en el formulario de
        // busqueda
        setLoading(true)
        let user = (reconocedores.filter(reconocedor => reconocedor.name === filtroParams?.user))[0] || ""
        user = !!user ? user.id : ""
        filtroParams = {
            "borradoresParams": {
                "data_predio": [
                    "id_predio",
                    "npn"
                ],
                "BorradorGeneral": [
                    "user_id",
                    "id",
                    "estado"
                ],
                "BorradorUsuarioName": true
            },
            ...filtroParams,
            "user": user
        }
        const [bodyParams, query] = bodyParamsQuery(filtroParams, page, sizePage, listaBorradores)

        const handleClick = async (e) => {
            e.preventDefault()
            const idBorrador = e.target.value

            history.push({
                pathname: `${Routes.ShowBorrador.route}${listaBorradores}/${idBorrador}`
            });
        }
               
        const [temp, tempRes] = await getBorradores(bodyParams, query, handleClick)
       
        setTBodySearched({ ...temp })
        setModoBusqueda(true)
        setGruposSize({
            ...gruposSize, sizeSearch: tempRes.totalResultados,
            groups: tempRes.maxPages,
            group: tempRes.page
        })
        setLoading(false)
    }

    const limpiarBusqueda = () => {
        setTBody({ ...tBody, ...tBodySearched })
        setTBodySearched({})
        setModoBusqueda(false)
    }

    const actualizarListas = (id, param, value) => {

        setLoading(true)
        if (modoBusqueda) {
            setTBodySearched((currentVal) => {
                const copy = { ...currentVal }
                if (param === "status") {
                    copy[id][param] = renderStatus(value)
                } else {
                    copy[id][param] = value
                }

                return { ...copy }
            })
        } else {
            setTBody((currentVal) => {
                const copy = { ...currentVal }
                if (param === "status") {
                    copy[id][param] = renderStatus(value)
                } else {
                    copy[id][param] = value
                }

                return { ...copy }
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        const getReconocedores = async () => {
            let response = await getReconocedoresMysql()
            const res = JSON.parse(response.data.data)
            const resSorted = res.map(data => ({ name: data.username, id: data.id }))
            setReconocedores(resSorted)
        }
        getReconocedores()
    }, []);

    return (
        <borradoresContext.Provider value={{ tBody, setTBody, tBodySearched, setTBodySearched, reconocedores, buscarDatosBorradores, buscarDatosShowBorrador, predios, setPredios, modoBusqueda, limpiarBusqueda, loading, actualizarListas, gruposSize, setGruposSize, valoresBusqueda, setValoresBusqueda, listaBorradores, setListaBorradores,sizePage, setSizePage }}>
            {children}
        </borradoresContext.Provider>
    )
}