import { Row } from '@themesberg/react-bootstrap';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import FotosModal from './FotosModal';

const Calificaciones = ({data, construccionesTipo}) => {

    const [images, setImages] = useState({ estructura: [], banio: [], acabados: [], cocina: [],industria:[] })

    useEffect(() => {

        let copyImages = { estructura: [], banio: [], acabados: [], cocina: [],industria:[] }

        if(data.imagenes !== undefined) {
            data.imagenes.forEach(res => {
              res.forEach(r => {
                if(r.name.includes('estructura')) {
                  copyImages.estructura.push(r)
                } else if(r.name.includes('banio')) {
                  copyImages.banio.push(r)
                } else if(r.name.includes('acabadosp')) {
                  copyImages.acabados.push(r)
                } else if(r.name.includes('cocina')) {
                  copyImages.cocina.push(r)
                }else if(r.name.includes('industria')) {
                  copyImages.industria.push(r)
                }
                
              })
            })
          }
      
          setImages(copyImages)
    }, []);


    const nameConstruccionTipo = (id) => {
        if(construccionesTipo !== undefined) {
          const obj = construccionesTipo.find(res => res.id == id)
          const arrayName = obj.nombre.split(".");
          const arrName = arrayName[1].split("_")
          return arrName.join(" ")
        }
      }
    
      const searchValue = (array, keyCond, key, value) => {
        const obj = array.find(res => value == res[keyCond])
        return obj[key]
      }
    
      const nameConveciones = (id) => {
        if(id == 1) {
          return 'Malo'
        } else if(id == 2) {
          return 'Regular'
        } else if(id == 3) {
          return 'Bueno'
        } else if(id == 4) {
          return 'Excelente'
        } else {
          return 'Sin calificar'
        }
      }
    
      const searchScoreConvencionales = (id) => {
        if(id == 1) {
          return 0
        } else if(id == 2) {
          return 2
        } else if(id == 3) {
          return 4
        } else if(id == 4) {
          return 5
        } else {
          return 'N/A'
        }
      }
    

  return (
      <>
        <Row md={2}>
            <div marginBottom={4}>
                <h5>Estructura</h5>
                    {
                        data.calificacion.armazon !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Armazón: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.armazon.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.armazon.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Armazón: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.calificacion.muros !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Muros: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.muros.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.muros.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Muros: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.calificacion.cubierta !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Cubierta: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.cubierta.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.cubierta.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Cubierta: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.cla_calificacion !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Conservación: </strong>
                                    <p>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 1))}</p>
                                </div>
                                <span>{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 1)}</span>
                            </div>
                        ) : (
                            data.cla_calif !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>{nameConveciones(data.cla_calif.conservacion_est.id_estadoconser)}</p>
                                    </div>
                                    <span>{searchScoreConvencionales(data.cla_calif.conservacion_est.id_estadoconser)}</span>
                                </div>
                            ) : (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>Sin Calificar</p>
                                    </div>
                                    <span>N/A</span>
                                </div>
                            )
                        )
                    }
                    <FotosModal images={images.estructura} />
            </div>

            <div>
                {data.id_undconstipo != 2 &&
                    <h5>Baños</h5>
                }
                {
                    data.id_undconstipo === 5 && (
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <strong>Tamaño: </strong>
                                <p>{data.calificacion.tamano_banos !== undefined ? nameConstruccionTipo(data.calificacion.tamano_banos.id_objeto) : "Sin calificar"}</p>
                            </div>
                            <span>{data.calificacion.tamano_banos !== undefined ? data.calificacion.tamano_banos.subtotal_estructura : "N/A"}</span>
                        </div>
                    )
                }
                    {
                        data.id_undconstipo === 5 &&  (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Enchape: </strong>
                                    <p>{data.calificacion.enchapes_ba !== undefined ? nameConstruccionTipo(data.calificacion.enchapes_ba.id_objeto) : "Sin calificar"}</p>
                                </div>
                                <span>{data.calificacion.enchapes_ba !== undefined ? data.calificacion.enchapes_ba.subtotal_estructura : "N/A"}</span>
                            </div>
                        )
                    }
                    {
                        data.id_undconstipo != 2 && (
                            data.calificacion.mobiliario_ba !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Mobiliario: </strong>
                                        <p>{nameConstruccionTipo(data.calificacion.mobiliario_ba.id_objeto)}</p>
                                    </div>
                                    <span>{data.calificacion.mobiliario_ba.subtotal_estructura}</span>
                                </div>
                            ) : (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Mobiliario: </strong>
                                        <p>Sin Calificar</p>
                                    </div>
                                    <span>N/A</span>
                                </div>
                            )
                        )
                    }
                    {
                        data.id_undconstipo === 5  && (
                            data.cla_calificacion !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 3))}</p>
                                    </div>
                                    <span>{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 3)}</span>
                                </div>
                            ) : (
                                data.cla_calif !== undefined ? (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <strong>Conservación: </strong>
                                            <p>{nameConveciones(data.cla_calif.conservacion_ba.id_estadoconser)}</p>
                                        </div>
                                        <span>{searchScoreConvencionales(data.cla_calif.conservacion_ba.id_estadoconser)}</span>
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <strong>Conservación: </strong>
                                            <p>Sin Calificar</p>
                                        </div>
                                        <span>N/A</span>
                                    </div>
                                )
                            )
                        )
                    }
                    <FotosModal images={images.banio} />
            </div>

            <div>
                <h5>Acabados Principales</h5>
                    {
                        data.calificacion.fachadas !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Fachada: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.fachadas.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.fachadas.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Fachada: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.calificacion.cubrimiento_muros !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Cubrimiento Muros: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.cubrimiento_muros.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.cubrimiento_muros.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Cubrimiento Muros: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.calificacion.pisos !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Pisos: </strong>
                                    <p>{nameConstruccionTipo(data.calificacion.pisos.id_objeto)}</p>
                                </div>
                                <span>{data.calificacion.pisos.subtotal_estructura}</span>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Pisos: </strong>
                                    <p>Sin Calificar</p>
                                </div>
                                <span>N/A</span>
                            </div>
                        )
                    }
                    {
                        data.cla_calificacion !== undefined ? (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Conservación: </strong>
                                    <p>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 2))}</p>
                                </div>
                                <span>{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 2)}</span>
                            </div>
                        ) : (
                            data.cla_calif !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>{nameConveciones(data.cla_calif.conservacion_a.id_estadoconser)}</p>
                                    </div>
                                    <span>{searchScoreConvencionales(data.cla_calif.conservacion_a.id_estadoconser)}</span>
                                </div>
                            ) : (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>Sin Calificar</p>
                                    </div>
                                    <span>N/A</span>
                                </div>
                            )
                        )
                    }
                    <FotosModal images={images.acabados} />
            </div>

            <div>
                {data.id_undconstipo != 2 &&
                    <h5>Cocina</h5>
                }
                {
                    data.id_undconstipo === 5 && (
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <strong>Tamaño: </strong>
                                <p>{data.calificacion.tamano_co !== undefined ? nameConstruccionTipo(data.calificacion.tamano_co.id_objeto) : "Sin calificar"}</p>
                            </div>
                            <span>{data.calificacion.tamano_co !== undefined ? data.calificacion.tamano_co.subtotal_estructura : "N/A"}</span>
                        </div>
                    )
                }
                    {
                        data.id_undconstipo === 5 &&  (
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <strong>Enchape: </strong>
                                    <p>{data.calificacion.enchapes_co !== undefined ? nameConstruccionTipo(data.calificacion.enchapes_co.id_objeto) : "Sin calificar"}</p>
                                </div>
                                <span>{data.calificacion.enchapes_co !== undefined ? data.calificacion.enchapes_co.subtotal_estructura : "N/A"}</span>
                            </div>
                        )
                    }
                    {
                        data.id_undconstipo != 2 && (
                            data.calificacion.mobiliario_co !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Mobiliario: </strong>
                                        <p>{nameConstruccionTipo(data.calificacion.mobiliario_co.id_objeto)}</p>
                                    </div>
                                    <span>{data.calificacion.mobiliario_co.subtotal_estructura}</span>
                                </div>
                            ) : (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Mobiliario: </strong>
                                        <p>Sin Calificar</p>
                                    </div>
                                    <span>N/A</span>
                                </div>
                            )
                        )
                    }
                    {
                        data.id_undconstipo === 5  && (
                            data.cla_calificacion !== undefined ? (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Conservación: </strong>
                                        <p>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 4))}</p>
                                    </div>
                                    <span>{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 4)}</span>
                                </div>
                            ) : (
                                data.cla_calif !== undefined ? (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <strong>Conservación: </strong>
                                            <p>{nameConveciones(data.cla_calif.conservacion_co.id_estadoconser)}</p>
                                        </div>
                                        <span>{searchScoreConvencionales(data.cla_calif.conservacion_co.id_estadoconser)}</span>
                                    </div>
                                ) : (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <strong>Conservación: </strong>
                                            <p>Sin Calificar</p>
                                        </div>
                                        <span>N/A</span>
                                    </div>
                                )
                            )
                        )
                    }
                    <FotosModal images={images.cocina} />
            </div>

            <div>
                {
                    data.id_undconstipo === 2 && (
                        data.id_uso_cosnt >= 42 && data.id_uso_cosnt < 45 ? (
                            <>
                                <h5>Cerchas</h5>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>Cerchas: </strong>
                                        <p>{data.calificacion.cerchas_Complemento_Industria.id_objeto !== undefined ? nameConstruccionTipo(data.calificacion.cerchas_Complemento_Industria.id_objeto) : "Sin calificar"}</p>
                                    </div>
                                    <span>{data.calificacion.cerchas_Complemento_Industria.subtotal_estructura !== undefined ? data.calificacion.cerchas_Complemento_Industria.subtotal_estructura : "N/A"}</span>
                                </div>
                                <FotosModal images={images.industria} />
                            </>
                        ) : (
                            (data.id_uso_cosnt === 40 || data.id_uso_cosnt === 41) && data.calificacion.cerchas_Complemento_Industria !== undefined && (
                                <>
                                    <h5>Cerchas</h5>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <strong>Cerchas: </strong>
                                            <p>{nameConstruccionTipo(data.calificacion.cerchas_Complemento_Industria.id_objeto)}</p>
                                        </div>
                                        <span>{data.calificacion.cerchas_Complemento_Industria.subtotal_estructura}</span>
                                    </div>
                                    <FotosModal images={images.industria} />
                                </>
                            )
                        )
                    )
                }
            </div>
        </Row>
      </>
  );
};

export default Calificaciones;
