import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

import AuthService from "../services/auth.service";

// pages
//import Presentation from "./Presentation";
// import Upgrade from "./Upgrade";
import Inicio from "./dashboard/Inicio";
import Usuarios from "./dashboard/Usuarios"
// eslint-disable-next-line no-unused-vars
import Validar from "./dashboard/Validar";
import ResetearContrasenaAdmin from "./dashboard/ResetearContrasenaAdmin";
// import Transactions from "./Transactions";
// import Settings from "./Settings";
// import BootstrapTables from "./tables/BootstrapTables";
import Login from "./others/Login";
import Registro from "./others/Registro";
import OlvidoContrasena from "./others/OlvidoContrasena";
import ResetearContrasena from "./others/ResetearContrasena";
import Lock from "./others/Lock";
import NotFoundPage from "./others/NotFound";
import ServerError from "./others/ServerError";

// documentacion pages
// import DocsOverview from "./documentacion/DocsOverview";
// import DocsDownload from "./documentacion/DocsDownload";
// import DocsQuickStart from "./documentacion/DocsQuickStart";
// import DocsLicense from "./documentacion/DocsLicense";
// import DocsFolderStructure from "./documentacion/DocsFolderStructure";
// import DocsBuild from "./documentacion/DocsBuild";
// import DocsChangelog from "./documentacion/DocsChangelog";
// import Terminos from "./documentacion/Terminos";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import Borradores from './dashboard/components/borradores/Borradores';
import ShowResume from './dashboard/components/borradores/ShowResume';
import LoadPredios from './dashboard/LoadPredios';

// import Accordion from "./components/Accordion";
// import Alerts from "./components/Alerts";
// import Badges from "./components/Badges";
// import Breadcrumbs from "./components/Breadcrumbs";
// import Buttons from "./components/Buttons";
// import Forms from "./components/Forms";
// import Modals from "./components/Modals";
// import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
// import Pagination from "./components/Pagination";
// import Popovers from "./components/Popovers";
// import Progress from "./components/Progress";
// import Tables from "./components/Tables";
// import Tabs from "./components/Tabs";
// import Tooltips from "./components/Tooltips";
// import Toasts from "./components/Toasts";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  let isAuthenticated;

  if( localStorage.getItem("isAuthenticated") !== null ) {
    isAuthenticated = localStorage.getItem("isAuthenticated");
  
    if(isAuthenticated) {
      const dataUser = AuthService.getCurrentUser();
  
      if( dataUser ) {
        if(dataUser.accessToken === null) {
          isAuthenticated = false;
        }
      } else {
        isAuthenticated = false;
      }
    } else {
      isAuthenticated = false;
    }
  } else {
    isAuthenticated = false;
  }

  //console.log(isAuthenticated);
  
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( !isAuthenticated ? <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> :
      <Redirect to="/dashboard/inicio"/> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  let isAuthenticated;

  if( localStorage.getItem("isAuthenticated") !== null ) {
    isAuthenticated = localStorage.getItem("isAuthenticated");
  
    if(isAuthenticated) {
      const dataUser = AuthService.getCurrentUser();
  
      if( dataUser ) {
        if(dataUser.accessToken === null) {
          isAuthenticated = false;
        }
      } else {
        isAuthenticated = false;
      }
    } else {
      isAuthenticated = false;
    }
  } else {
    isAuthenticated = false;
  }

  //console.log(isAuthenticated);

  return (
    <Route {...rest} render={props => (
      isAuthenticated ?
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
      :
      <Redirect to="/"/>
    )}
    />
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Switch>
    {/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> */}
    <RouteWithLoader exact path={Routes.Login.path} component={Login} />
    <RouteWithLoader exact path={Routes.Registro.path} component={Registro} />
    <RouteWithLoader exact path={Routes.OlvidoContrasena.path} component={OlvidoContrasena} />
    <RouteWithLoader exact path={Routes.ResetearContrasena.path} component={ResetearContrasena} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.Inicio.path} component={Inicio} />
    <RouteWithSidebar exact path={Routes.ResetearContrasenaAdmin.path} component={ResetearContrasenaAdmin} />
    <RouteWithSidebar exact path={Routes.Validar.path} component={Validar} />
    <RouteWithSidebar exact path={Routes.Usuarios.path} component={Usuarios} />
    <RouteWithSidebar exact path={Routes.Borradores.path} component={Borradores} />
    <RouteWithSidebar exact path={Routes.ShowBorrador.path} component={ShowResume} />
    <RouteWithSidebar exact path={Routes.loadPredios.path} component={LoadPredios} />
    {/* <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} /> */}

    {/* components */}
    {/* <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} /> */}

    {/* documentacion */}
    {/* <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />
    <RouteWithLoader exact path={Routes.Terminos.path} component={Terminos} /> */}

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
