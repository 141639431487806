
export const Routes = {
    // pages
    //Presentation: { path: "/" },
    Login: { path: "/" },
    Inicio: { path: "/dashboard/inicio" },
    Validar: { path: "/dashboard/validar" },
    Usuarios: { path: "/dashboard/usuarios" },
    Borradores: { path: "/dashboard/borradores" },
    ShowBorrador: { path: "/dashboard/borradores/:estado_list/:id", route: "/dashboard/borradores/" },
    loadPredios: { path: "/dashboard/load-predios" },
    // Transactions: { path: "/transactions" },
    // Settings: { path: "/settings" },
    // Upgrade: { path: "/upgrade" },
    // BootstrapTables: { path: "/tables/bootstrap-tables" },
    // Billing: { path: "/others/billing" },
    // Invoice: { path: "/others/invoice" },
    // //Signin: { path: "/others/sign-in" },
    Registro: { path: "/registro" },
    OlvidoContrasena: { path: "/olvido-contrasena" },
    ResetearContrasena: { path: "/resetear-contrasena/:id" },
    ResetearContrasenaAdmin: { path: "/dashboard/resetear-contrasena-admin" },
    Lock: { path: "/others/lock" },
    NotFound: { path: "/error404" },
    ServerError: { path: "/error500" },
    
    // docs
    // DocsOverview: { path: "/documentacion/overview" },
    // DocsDownload: { path: "/documentacion/download" },
    // DocsQuickStart: { path: "/documentacion/quick-start" },
    // DocsLicense: { path: "/documentacion/license" },
    // DocsFolderStructure: { path: "/documentacion/folder-structure" },
    // DocsBuild: { path: "/documentacion/build-tools" },
    // DocsChangelog: { path: "/documentacion/changelog" },
    Terminos: { path: "/terminos" },

    // components
    // Accordions: { path: "/components/accordions" },
    // Alerts: { path: "/components/alerts" },
    // Badges: { path: "/components/badges" },
    // Widgets: { path: "/widgets" },
    // Breadcrumbs: { path: "/components/breadcrumbs" },
    // Buttons: { path: "/components/buttons" },
    // Forms: { path: "/components/forms" },
    // Modals: { path: "/components/modals" },
    // Navs: { path: "/components/navs" },
    // Navbars: { path: "/components/navbars" },
    // Pagination: { path: "/components/pagination" },
    // Popovers: { path: "/components/popovers" },
    // Progress: { path: "/components/progress" },
    // Tables: { path: "/components/tables" },
    // Tabs: { path: "/components/tabs" },
    // Tooltips: { path: "/components/tooltips" },
    // Toasts: { path: "/components/toasts" },
    // WidgetsComponent: { path: "/components/widgets" }
};