import React from "react";
import {
  Box,
  VStack,
  Text,
  HStack,
  ScrollView,
  Stack,
  TextArea,
  Image,
} from "native-base";
import { InteresadoTable } from "./InteresadoTable";
import { TerrenoTable } from "./TerrenoTable";
import { ConstruccionTable } from "./ConstruccionTable";
import { ContactoTable } from "./ContactoTable";

export const ResumenBorrador = ({ data, index, handleChangeObservations, construccionesTipo, selects, read = false }) => {

  const searchValueDestination = (id) => {
    const res = selects.destinacion.find((d) => d.id_destecono === id)
    return res.desc_destecono
  }

  console.log('observ',data.observaciones)

  return (

    <ScrollView>
        <VStack space={6} bg="white" p={5}>
          <HStack width="100%" px={3} mx={10}>
            <Text width="50%">
              <Text bold>Indentificación del Predio:</Text>{" "}
              {data.data_predio.general[0].id_predio}
            </Text>
            <Text width="50%">
              <Text bold>Fecha de Matrícula:</Text>{" "}
              {data.data_predio.interesados[0]?.fecha_matricula}
            </Text>
          </HStack>
          <HStack width="100%" px={3} mx={10}>
            <Text width="50%">
              <Text bold>Dirección Predio:</Text>{" "}
              {data.data_predio.general[0].direccion_predio}
            </Text>
            <Text width="50%">
              <Text bold>Número de Matrícula:</Text>{" "}
              {data.data_predio.interesados[0]?.numero_matricula}
            </Text>
          </HStack>
          <HStack width="100%" px={3} mx={10}>
            <Text width="50%">
              <Text bold>NPN:</Text> {data.data_predio.general[0].npn}
            </Text>
            <Text width="50%">
              <Text bold>Destinación económica:</Text> {searchValueDestination(data.data_predio.general[0].id_destecono)}
            </Text>
          </HStack>
          <HStack width="100%" px={3} mx={10}>
            <Text width="50%">
              <Text bold>Inicio Vida Útil:</Text>{" "}
              {/* {predioData[0].inicio_vida_util} */}
            </Text>
            <Text width="50%">
              <Text bold>Fin Vida Útil:</Text> 
              {/* {predioData[0].fin_vida_util} */}
            </Text>
          </HStack>
          <HStack width="100%" px={3} mx={10}>
            <Text width="50%">
              <Text bold>Observaciones:</Text>{" "}
              {data.data_predio.general[0].observacion}
            </Text>
          </HStack>
          {
            data.data_predio.interesado !== undefined ? (
              data.data_predio.interesado.length !== 0 ? (
                <InteresadoTable dataInteresados={data.data_predio.interesado} />
              ) : null
            ) : null
          }
          {
            data.data_predio.terreno !== undefined ? (
              data.data_predio.terreno.length !== 0 ? (
                <TerrenoTable dataTerrenos={data.data_predio.terreno} />
              ) : null
            ) : null
          }
          {
            data.data_predio.dibujo_terreno !== undefined && (
              <HStack>
                <Box display="flex" flexDirection="column" margin="auto">
                  <Text textAlign="center" marginBottom={4}>Croquis</Text>
                  <Image
                    alt="2"
                    source={{ uri: data.data_predio.dibujo_terreno.uri }}
                    style={{ width: 420, height: 200 }}
                  />
                </Box>
              </HStack>
            )
          }
          {
            data.data_predio.unidades_construccion !== undefined && data.data_predio.unidades_construccion.length !== 0 ? (
              data.data_predio.unidades_construccion.length !== 0 ? (
                <ConstruccionTable dataConstrucciones={data.data_predio.unidades_construccion} construccionesTipo={construccionesTipo} selects={selects} />
              ) : null
            ) : null
          }
          {
            data.data_predio.contactos !== undefined ? (
              data.data_predio.contactos.length !== 0 ? (
                <ContactoTable dataContactos={data.data_predio.contactos} />
              ) : null
            ) : null
          }
          <Stack space={4} marginLeft="5%" w="90%">
            {
              //Observaciones en Reconocedor
              data.data_predio?.observaciones !== undefined ?
              <TextArea h={20} placeholder="Comentarios" onChangeText={read ? () => {} : (text) =>handleChangeObservations(text, index)} value={data.data_predio.observaciones} />
              :
              //Observaciones en Revisión
              <TextArea h={20} placeholder="Comentarios" onChangeText={read ? () => {} : (text) =>handleChangeObservations(text, index)} value={data.observaciones !== null ? data.observaciones : ''} />
            }
          </Stack>
        </VStack>
    </ScrollView>
  );
}
