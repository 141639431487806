import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";

import AuthService from "../../services/auth.service";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const [userData, setUserData] = useState({ email: "", password: "", password2: "" });
  const [btns, setBtns] = useState({ email: false, password: false, password2: false });
  const [errorMessage, setErrorMessage] = useState({ value: "" });

  const history = useHistory();

  ////console.log("auth", localStorage.getItem("isAuthenticated"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setUserData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

  };

  const handleInputOnKeyUp = (e) => {
    const { name, value } = e.target;
    //console.log(btns);

    setBtns((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (userData.email === "" || userData.password === "" || userData.password2 === "") {
      setErrorMessage((prevState) => ({
        type: 1, value: "Campos vacíos",
      }));
      return;
    }

    if (userData.password !== userData.password2 ) {
      setErrorMessage((prevState) => ({
        type: 1, value: "Las contraseñas no son iguales",
      }));
      return;
    }

    setErrorMessage((prevState) => ({ type: 3, value: "Loading" }));

    AuthService.resetPassword(userData).then(
      async (response) => {

          if( response ) {

            setTimeout(function(){
              setErrorMessage((prevState) => ({ type:2, value:"Contraseña modificada" }));
            },1000)
            setTimeout(function(){
              history.push("/");
            },2000)
          } else {
            setErrorMessage((prevState) => ({ type:1, value:"No se pudo modificar la contraseña" }));
          }
      },
      (error) => {
          setErrorMessage((prevState) => ({ type:1, value: error.response.data.message }));
      }
    );
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Login.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Regresar
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Resetear contraseña</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Correo</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.email !== false ? btns.email === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="email" type="email" placeholder="Ejemplo: ejemplo@google.com" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite el correo.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Nueva contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.password !== false ? btns.password === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="password" type="password" placeholder="Contraseña" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite la nueva contraseña.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirmar contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.password2 !== false ? btns.password2 === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="password2" type="password" placeholder="Confirmar Contraseña" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite nuevamente la constraseña.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  {errorMessage.value && ( errorMessage.type === 1 ?
                    <div style={{textAlign:'center'}}><p className="text-danger"> {errorMessage.value} </p></div> : errorMessage.type === 2 ?
                    <div style={{textAlign:'center'}}><p className="text-success"> {errorMessage.value} </p></div> : <div style={{textAlign:'center', marginBottom: 20}}><Spinner animation="border" size="sm" /></div>
                  ) }
                  <Button variant="primary" type="submit" className="w-100">
                    Resetear contraseña
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
