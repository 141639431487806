import React, {useState, useEffect} from 'react'
import { StyleSheet } from 'react-native';
import { Table, Row } from '@themesberg/react-bootstrap';
import { Text } from 'native-base';

export const InteresadoTable = ({dataInteresados}) => {
  const tableHead = ['Tipo de identificación', 'Número de identificación', 'Nombre Interesado', 'Inicio Vida Útil', 'Fin Vida Útil'];
  

  const [interesados, setInteresados] = useState([]);

  useEffect(()=> {

    const dataT = dataInteresados.map(resp => ([resp.tipo_documento_id, resp.documento_identidad, resp.primer_nombre !== '' ? `${resp.primer_nombre} ${resp.segundo_nombre !== null ? resp.segundo_nombre : ''} ${resp.primer_apellido} ${resp.segundo_apellido !== null ? resp.segundo_apellido : ''}` : resp.razon_social, resp.comienzo_vida_util, resp.fin_vida_util]))

    setInteresados(dataT)
  }, [dataInteresados])

  const nameDocumentById = (id) => {
    if(id !== undefined && id !== null) {
      const idNew = id.split('')
      switch (idNew[0]) {
        case "1":
          return "Cédula de ciudadanía"
        case "2":
          return "Cédula de extranjería"
        case "3":
          return "NIT"
        case "4":
          return "Tarjeta de identidad"
        case "5":
          return "Registro civil"
        case "6":
          return "Secuencial"
        case "7":
          return "sin_definir"
        case "8":
          return "Pasaporte"
      
        default:
          break;
      }
    } else {
      return "Null"
    }
  }

  return (
    <>
      <Text mx={5} my={2}>Información de Interesados</Text>
      <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
        <Row data={tableHead} style={styles.head} textStyle={styles.textHeader}/>
        {
          interesados.map((rowData, index) => (
            <th key={index} style={styles.row}>
              {
                rowData.map((cellData, cellIndex) => (
                  cellIndex === 0 ? (
                    <th key={cellIndex} data={nameDocumentById(cellData)} textStyle={styles.textData}/>
                  ) : (
                    <th key={cellIndex} data={cellData} textStyle={styles.textData}/>
                  )
                ))
              }
            </th>
          ))
        }
      </Table>
    </>
  )
}


const styles = StyleSheet.create({
  head: { height: 40, backgroundColor: '#808B97' },
  textHeader: { margin: 6, color: '#fff', textAlign: "center" },
  textData: { margin: 6, textAlign: "center" },
  row: { flexDirection: 'row'},
});