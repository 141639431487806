import { Button, Card, Table } from '@themesberg/react-bootstrap';
import React from 'react';
import { useState } from 'react';
import ModalCalificaciones from './ModalCalificaciones';

const Unidades = ({dataConstrucciones, selects, construccionesType}) => {

    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const handleOpenModal = (data) => {
        setShowModal(true)
        setDataModal(data)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

  return (
        <>
        <Card>
            <Card.Body>
                <Card.Title>Información de Construcciones</Card.Title>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Id unidad</th>
                            <th>Tipo de Const.</th>
                            <th>Tipo Unidad</th>
                            <th>Destino Económico</th>
                            <th>Área construcción</th>
                            <th>Inicio Vida útil</th>
                            <th>Fin Vida útil</th>
                            <th>Puntaje Total</th>
                            <th>Número pisos</th>
                            <th>Año construcción</th>
                            <th>Número baños</th>
                            <th>Número locales</th>
                            <th>Planta ubicación</th>
                            <th>Altura</th>
                            <th>Calificaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataConstrucciones.map((construccion, index) => (
                                <tr key={index}>
                                    <td>{construccion.id_unidad}</td>
                                    <td>{construccion.tipo_construccion}</td>
                                    <td>{construccion.tipo_unidad}</td>
                                    <td>{construccion.destino}</td>
                                    <td>{construccion.area_construida}</td>
                                    <td>{construccion.comienzo_vida_util}</td>
                                    <td>{construccion.fin_vida_util}</td>
                                    <td>{construccion.total_puntaje}</td>
                                    <td>{construccion.total_pisos}</td>
                                    <td>{construccion.anio_construccion}</td>
                                    <td>{construccion.total_banos}</td>
                                    <td>{construccion.total_locales}</td>
                                    <td>{construccion.planta_ubicacion}</td>
                                    <td>{construccion.altura}</td>
                                    <td>
                                        {
                                            construccion.retirado == 2 ? (
                                                <span>Retirado</span>
                                            )
                                            : (
                                                construccion?.calificacion ? (
                                                    <Button variant='primary' size='sm' onClick={() => {handleOpenModal(construccion)}} >Ver</Button>
                                                ) 
                                                : construccion.tipo_construccion != 'Convencional' ? (
                                                    <Button variant='primary' size='sm' onClick={() => {handleOpenModal(construccion)}}  >Ver</Button>
                                                )
                                                :
                                                <span>Sin calificación</span>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        {
            showModal &&
            <ModalCalificaciones showModal={showModal} handleCloseModal={handleCloseModal} data={dataModal} selects={selects} construccionesType={construccionesType} />
        }
        </>
  );
};

export default Unidades;
