import React, { useMemo } from 'react'
import { Col, Form, Pagination, Row, Table } from '@themesberg/react-bootstrap';
import { useGlobalFilter, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

const DataTable = ({ tHead = [], tBody = [] }) => {

    const columns = useMemo(() => [...tHead], [])
    const data = useMemo(() => [...tBody], [])
    const table = useTable({ columns, data }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = table

    useEffect(() => {
        setPageSize(5)
    }, [])
    return (
        <>
            <Row>
                <Col xs={6} md={6} >
                    <Form.Control
                        type="text"
                        value={globalFilter || ""}
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder="Ingresa el texto de lo que deseas buscar..."
                        size="sm"
                        autoFocus
                    />
                </Col>
            </Row>
            <Table hover striped responsive {...getTableProps()} className='my-2'>
                <thead className="thead-light">
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}{' '}
                                                <span>
                                                    {column.canSort &&
                                                        (column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <FontAwesomeIcon icon={faSortDown} />
                                                                : <FontAwesomeIcon icon={faSortUp} />
                                                            : <FontAwesomeIcon icon={faSort} />)}
                                                </span>
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <div className='d-flex flex-row justify-content-between'>
                <div>
                    <Pagination className='d-flex flex-row align-items-center'>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        <span className='mx-2'>Pág{" "}
                            <strong>
                                {pageOptions.length === 0 ? 0 : pageIndex + 1} de {pageOptions.length}
                            </strong>
                        </span>
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
                </div>
                <div>
                    <Form.Select
                        size="sm"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Mostrar {pageSize} registros
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div>
                    <span>Viendo {page.length} de {rows.length} registros.</span>
                </div>
            </div>
        </>
    )
}

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <Row>
            <Col xs={12}>
                <Form.Control
                    type="text"
                    value={filterValue || ""}
                    onChange={e => setFilter(e.target.value)}
                    placeholder="Ingresa el texto de lo que deseas buscar..."
                    size="sm"
                />
            </Col>
        </Row>
    )
}

export default DataTable
