import axios from "axios";
import config from "../config";

const API_URL = config.API_URL;

export const getPrediosByUser = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `user_predios/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const getListPrediosBorradores = () => {
    return new Promise(function (resolve, reject) {
      axios.get(API_URL + "general/predios_borradores")
        .then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err)
        })
    });
};

export const postBorradores = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const postBorradoresBackup = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores_backup", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const getBorradoresRevisados = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/get_revisados/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const getBorradoresBackup = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/get_backup/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const updateBorradoresState = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/update_state/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const updateBorradores = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores/update", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

export const updateBorradoresBackup = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores/update_backup", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Obtener predios del usuario conservacion
export const getListCompleteDataUserPrediosConservation = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `complete_user_predios_conservation/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Descargar datos del predio conservacion
export const getFullDataPredioConservation = (list_predios) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + `catastro/full_predio_conservation/${list_predios}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Actualizar borrador conservacion
export const updateBorradoresConservation = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores/update_conservation", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Post borradores conservation
export const postBorradoresConservation = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores_conservation", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Update borradores Backup Conservation
export const updateBorradoresBackupConservation = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores/update_backup_conservation", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Post borradores backup conservation
export const postBorradoresBackupConservation = (data) => {
  return new Promise(function (resolve, reject) {
    axios.post(API_URL + "borradores_backup_conservation", data)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Get borradores backup conservation
export const getBorradoresBackupConservation = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/get_backup_conservation/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Update state borradores conservaation
export const updateBorradoresStateConservation = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/update_state_conservation/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Get revisados conservation
export const getBorradoresRevisadosConservation = (user_id) => {
  return new Promise(function (resolve, reject) {
    axios.get(API_URL + `borradores/get_revisados_conservation/${user_id}`)
      .then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
  });
};

//Post relación predios reconocedores Conservation
export const postPrediosMySqlConservation = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + "predios-mysql_conservacion", data)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Para elexport conservation
export const getUserPrediosConservation = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `user_predios_conservacion/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};

//Para la lista de borradores en Conservation
export const getBorradoresConservation = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(API_URL + `borradores_conservation`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//Actualizar estado del borrador en conservación
export const updateEstadoBorradoresConservation = (id, estado, observaciones, npn) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(API_URL + `update_estado_borradores_conservation/${id}/${estado}/${npn}`, observaciones)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}