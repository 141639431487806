import { Button } from "@themesberg/react-bootstrap"
import { getBorradoresConsulta } from "../../../services/catastro_m3.service"
import { renderMunicipios } from "./renderMunicipios"
import { renderStatus } from "./renderStatus"

export const getBorradores = async (bodyParams, query, handleClick) => {

    // Obtiene una lista de borradores segun lo datos buscados en el cuadro de
    // busqueda de la sección borradores
    try {
        const response = await getBorradoresConsulta(bodyParams, query)
        const data = JSON.parse(response.data.data)
        const resData = data.map((res, index) => ({ ...res, index: index }))
        let temporalTBody = {}
        for (const borrador of resData) {
            temporalTBody[borrador.id] = {
                id: borrador.id_predio,
                city: renderMunicipios(borrador.npn),
                npn: borrador.npn,
                status: renderStatus(borrador.estado),
                user: borrador.username,
                actions: <Button value={borrador.id} onClick={(e) => handleClick(e)} size="xs">Ver Resumen</Button>
            }
        }
        return [temporalTBody, { maxPages: response.data.maxPages, page: response.data.page, sizePage: response.data.sizePage, "totalResultados": response.data.totalResultados }]
    } catch (error) {
        console.log(error);
        return [{}, { maxPages: 0, page: 0, sizePage: 0, "totalResultados": 0 }]
    }


}