import axios from "axios";
import config from "../config";

const API_URL = config.API_URL;

const sendEmail = (data) => {
  return axios.post(API_URL + "mail/sendemail", data).then((response) => {
    return response.data;
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sendEmail,
};
