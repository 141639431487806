import axios from "axios";
import { API_URL } from "../config";

export const getBorradoresConsulta = (body = {}, query = []) => {
    // Devuelve los datos de los borradores según se solicite por el body y la query
    let endPoint = "borradores_por_pagina_o_consulta/";

    if (!!query.length) {
        endPoint = `${endPoint}?${query.join("&")}`
    }

    if (!!Object.keys(body).length) {
        return new Promise(function (resolve, reject) {
            axios.post(API_URL + endPoint, body)
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    return new Promise(function (resolve, reject) {
        axios
            .get(API_URL + endPoint)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });


}