
import React from "react";
import { Col, Row, Container, Card} from '@themesberg/react-bootstrap';
import AuthService from "../../services/auth.service";


const dataUser = AuthService.getCurrentUser();
let ReUsername;

if( dataUser ){
  ReUsername = dataUser.username.toLowerCase();
  ReUsername = ReUsername.charAt(0).toUpperCase() + ReUsername.slice(1);
} else {
  ReUsername = "";
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <Card.Body>
                <article>
                  <h1 className="h2" id="license">Bienvenido, {ReUsername}</h1>
                  <p className="fs-5 fw-light">Administrador de la aplicación móvil</p>
                </article>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
