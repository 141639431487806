import React from "react";
import { StyleSheet } from "react-native";
import { Row, Table } from '@themesberg/react-bootstrap';
import { Text, Spinner, Center, HStack, Icon, VStack } from "native-base";
import { RowBorrador } from './RowBorrador';

export const BorradoresTable =({borradores, getResumen, loadingInitial, hide, pagination, next, previous, counter}) => {
  
  const tableHead = [
    "ID Predio",
    "NPN",
    "Reconocedor",
    "Seleccionar Predio",
  ];
  
  return loadingInitial ? (
    borradores.length > 0 ? (
      <>
        <Text bold alignSelf="center" fontSize="md" mb={5}>
          Lista de borradores
        </Text>
        <Table>
          <Row
            data={tableHead}
            flexArr={[1, 2, 1, 1]}
            style={styles.head}
            textStyle={styles.textHeader}
            borderStyle={{ borderWidth: 2, borderColor: "#c8e1ff" }}
          />
          {
            borradores.map((item, index) => {
              return (
                <RowBorrador
                  key={index}
                  index={index}
                  rowData={item}
                  getResumen={getResumen}
                />
              )
            })
          }
        </Table>
        <HStack display="flex" justifyContent="flex-end" marginRight={8} alignItems="center">
          {
            hide.previous ? (
              <Icon
                size={10}
                style={{marginRight: 4}}
                onPress={previous}
              />
            ) : <VStack></VStack>
          }
          <Text>{pagination.initial} - {pagination.end}</Text>
          {
            hide.next ? (
              <Icon
                size={10}
                style={{marginLeft: 4}}
                onPress={next}
              />
            ) : <VStack></VStack>
          }
        </HStack>
      </>
    ) : <Center><Text>No hay predios por verificar</Text></Center>
  ) : <Spinner accessibilityLabel={"Cargando Información"} />
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: "#fff" },
  head: { height: 40, backgroundColor: "#808B97" },
  textHeader: { margin: 6, color: "#fff", textAlign: "center" },
  textData: { margin: 6, textAlign: "center", textTransform: "capitalize" },
  row: { flexDirection: "row" },
  btn: { textAlign: "center", backgroundColor: "#78B7BB", padding: 1, width: "80%", borderRadius: 4, marginLeft: "10%", color: "#fff" },
  btnText: { textAlign: "center", color: "#fff" },
});
