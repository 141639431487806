import { Card, Table } from '@themesberg/react-bootstrap';
import React from 'react';

const Terreno = ({data}) => {

  return (
        <Card>
            <Card.Body>
                <Card.Title>Información de Terreno</Card.Title>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {/* <th>Área de terreno</th> */}
                            <th>Área común</th>
                            <th>Área Total Terreno</th>
                            <th>Inicio Vida útil</th>
                            <th>Fin Vida útil</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            typeof data.data_predio.terreno !== 'undefined' ? (
                                data.data_predio.terreno.map((terreno, index) => (
                                    <tr key={index}>
                                        {/* <td>{terreno.area_terreno}</td> */}
                                        <td>{terreno.area_comun}</td>
                                        <td>{terreno.area_total}</td>
                                        <td>{terreno.comienzo_vida_util}</td>
                                        <td>{terreno.fin_vida_util}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>No contiene datos</tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
  );
};

export default Terreno;
