export const renderMunicipios = (oldI) => {
   

let f_status = oldI.slice(0, 5);
    
const Municipios = [
{value:'76001', label:'CALI'},
{value:'76020', label:'ALCALÁ'},
{value:'76036', label:'ANDALUCÍA'},
{value:'76041', label:'ANSERMANUEVO'},
{value:'76054', label:'ARGELIA'},
{value:'76100', label:'BOLÍVAR'},
{value:'76109', label:'BUENAVENTURA'},
{value:'76111', label:'GUADALAJARA DE BUGA'},
{value:'76113', label:'BUGALAGRANDE'},
{value:'76122', label:'CAICEDONIA'},
{value:'76126', label:'CALIMA'},
{value:'76130', label:'CANDELARIA'},
{value:'76147', label:'CARTAGO'},
{value:'76233', label:'DAGUA'},
{value:'76243', label:'EL ÁGUILA'},
{value:'76246', label:'EL CAIRO'},
{value:'76248', label:'EL CERRITO'},
{value:'76250', label:'EL DOVIO'},
{value:'76275', label:'FLORIDA'},
{value:'76306', label:'GINEBRA'},
{value:'76318', label:'GUACARÍ'},
{value:'76364', label:'JAMUNDÍ'},
{value:'76377', label:'LA CUMBRE'},
{value:'76400', label:'LA UNIÓN'},
{value:'76403', label:'LA VICTORIA'},
{value:'76497', label:'OBANDO'},
{value:'76520', label:'PALMIRA'},
{value:'76563', label:'PRADERA'},
{value:'76606', label:'RESTREPO'},
{value:'76616', label:'RIOFRÍO'},
{value:'76622', label:'ROLDANILLO'},
{value:'76670', label:'SAN PEDRO'},
{value:'76736', label:'SEVILLA'},
{value:'76823', label:'TORO'},
{value:'76828', label:'TRUJILLO'},
{value:'76834', label:'TULUÁ'},
{value:'76845', label:'ULLOA'},
{value:'76863', label:'VERSALLES'},
{value:'76869', label:'VIJES'},
{value:'76890', label:'YOTOCO'},
{value:'76892', label:'YUMBO'},
{value:'76895', label:'ZARZAL'}
]
//
let muni = "SIN MUNICIPIO";
let items_m_filter = Municipios.filter((myitem) => {

   if( myitem.value === f_status){
      muni=myitem.label;
      //return items_m_filter
                                 }
  
     
    
  });
 

   return muni;

}