import { Button, Card, Container, FormControl, Image, ListGroup, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useBorradores } from '../../../../context/borradores/BorradoresContext';
import { Routes } from '../../../../routes';
import catastroService from '../../../../services/catastro.service';
import { selects_calif_correcta } from '../../../../services/catastro_construcciones';
import CatastroServiceJ from '../../../../services/catastro_j.service'
import { setFullDataPredio, updateEstadoBorradores, updateEstadoBorradoresConservacion } from '../../../../services/catastro_m2.service';
import mailService from '../../../../services/mail.service';
import ContactoVisita from './components/ContactoVisita';
import Interesados from './components/Interesados';
import Terreno from './components/Terreno';
import Unidades from './components/Unidades';

const ShowResume = () => {
    let history = useHistory();
    const [data, setData] = useState({});
    const [isData, setIsData] = useState(false);
    const [selects, setSelects] = useState([]);
    const [dataSearch, setDataSearch] = useState({});
    const [construccionesType, setConstruccionesType] = useState([]);
    const [observations, setObservations] = useState('');
    const [users, setUsers] = useState([]);
    const { actualizarListas, buscarDatosShowBorrador } = useBorradores()

    let { id: idBorrador, estado_list: listaBorrador } = useParams()

    const handleChangeObservations = ({ target }) => {
        let newValue = target.value;

        const regexList = [/^[a-zA-Z0-9\u0020]+$/, /[áéíóúÁÉÍÓÚ]/]
        if (regexList.some((regex) => regex.test(newValue))) {
            setObservations(newValue)
            setData({ ...data, observaciones: newValue })
        }

        if (!newValue) {
            setObservations("")
            setData({ ...data, observaciones: "" })
        }
    }

    const getEmail = (id) => {
        // eslint-disable-next-line eqeqeq
        const obj = users.find(res => id == res.id)
        return obj.email
    }

    const handleDecline = async () => {
        Swal.fire({
            title: `Está seguro de Rechazar este predio?`,
            showCancelButton: true,
            confirmButtonText: 'Estoy Seguro',
            cancelButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire('Por favor espere')
                Swal.showLoading()
                try {
                    let response = null

                    if (listaBorrador === 'actualizacion') {
                        response = await updateEstadoBorradores(data.id, 2, { observaciones: data.observaciones }, data.data_predio.general[0].npn)
                    } else {
                        response = await updateEstadoBorradoresConservacion(data.id, 2, { observaciones: data.observaciones }, data.data_predio.general[0].npn)
                    }

                    if (response.status === 200) {
                        actualizarListas(idBorrador, "status", 2)
                    }

                    await mailService.sendEmail({
                        title: 'Valle avanza',
                        email: getEmail(data.user_id),
                        subject: `Borrador con npn ${data.data_predio.general[0].npn} fue rechazado`,
                        html: `
                      <div>
                        <h3 style={{margin-bottom: 2rem}}>Buen dia</h3>
                        <p style={{margin-bottom: 2rem}}>El borrador con npn ${data.data_predio.general[0].npn} ha sido rechazado. Debe entrar a la aplicación móvil  en la sección editar y revisar los detalles de la no aprobación.</p>
                        <p>Gracias</p>
                      </div>
                    `
                    })
                    Swal.fire(`El predio ha sido rechazado satisfactorialmente`, '', 'success')

                    setTimeout(() => {
                        history.push(Routes.Borradores.path)
                    }, 2000)
                } catch (error) {
                    Swal.fire('Ocurrió un error!', '', 'error')
                }
            }
        })
    }

    const handleSuccess = async () => {

        let reject = false;

        // eslint-disable-next-line eqeqeq
        reject = data.data_predio.unidades_construccion.some(element => element.retirado != 2 && element.area_construida == "")

        if (reject) {
            Swal.fire('Error aprobando!', 'Area construida vacia', 'error')
        } else {
            Swal.fire({
                title: `Está seguro de Aceptar este predio?`,
                showCancelButton: true,
                confirmButtonText: 'Estoy Seguro',
                cancelButtonText: `Cancelar`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire('Por favor espere')
                    Swal.showLoading()
                    try {
                        let response = null
                        let response2 = null

                        if (listaBorrador === 'actualizacion') {
                            response = await updateEstadoBorradores(data.id, 1, { observaciones: data.observaciones }, data.data_predio.general[0].npn)
                            response2 = await setFullDataPredio(data.data_predio)
                        } else {
                            response = await updateEstadoBorradoresConservacion(data.id, 1, { observaciones: data.observaciones }, data.data_predio.general[0].npn)
                            response2 = await setFullDataPredio(data.data_predio)
                        }

                        if (response.status === 200) {
                            actualizarListas(idBorrador, "status", 1)
                        }

                        console.log(response2)
                        await mailService.sendEmail({
                            title: 'Valle avanza',
                            email: getEmail(data.user_id),
                            subject: `Estado NPN:${data.data_predio.general[0].npn}`,
                            html: `
                          <div>
                            <h3 style={{margin-bottom: 2rem}}>Buen dia</h3>
                            <p style={{margin-bottom: 2rem}}>El borrador con npn ${data.data_predio.general[0].npn} ha sido aprobado.</p>
                            <p>Gracias</p>
                          </div>
                        `
                        })
                        Swal.fire(`El predio ha sido aceptado satisfactorialmente`, '', 'success')

                        setTimeout(() => {
                            history.push(Routes.Borradores.path)
                        }, 2000)
                    } catch (error) {
                        Swal.fire('Ocurrió un error!', '', 'error')
                    }
                }
            })
        }
    }

    useEffect(() => {
        const getDataSelects = async () => {
            const response = await CatastroServiceJ.getSelects()
            const infoSelects = response.data.data[0];
            setSelects(infoSelects)
        }

        const getDataType = async () => {
            const response = await selects_calif_correcta()
            const dataApiSelects = response.data.data;
            setConstruccionesType(dataApiSelects)
        }

        getDataSelects()
        getDataType()
    }, []);


    useEffect(() => {
        const getData = async (id, lista) => {
            try {
                const predio = await buscarDatosShowBorrador(id, lista)
                setData(predio)
                setObservations(predio.observaciones)
                setIsData(true)
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salio mal',
                    footer: `${error}`
                })
                setTimeout(() => {
                    history.push(Routes.Borradores.path)
                }, 2000)
            }
        }

        getData(idBorrador, listaBorrador)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idBorrador, listaBorrador]);

    useEffect(() => {
        const getOtherInfo = () => {
            let id = data.data_predio.general[0].id_destecono
            const res = selects.destinacion.find((d) => d.id_destecono == id)
            setDataSearch({
                desc_destecono: res.desc_destecono
            })
        }

        const fetchUsers = () => {
            catastroService.getListUser()
                .then(
                    (response) => {
                        setUsers(response.data)
                    },
                    (err) => {
                        console.log(err)
                    }
                );
        }

        if (data?.data_predio && selects?.destinacion) {
            getOtherInfo()
        }
        fetchUsers()
    }, [data, selects, isData]);

    return (
        <>
            {isData &&
                <Container className="px-0">
                    <Card>
                        <Card.Body>
                            <Card.Title>Información General</Card.Title>
                            <Row md={2}>
                                <ListGroup>
                                    <ListGroup.Item><strong>Identificación del Predio: </strong>{data.data_predio.general[0].id_predio}</ListGroup.Item>
                                    <ListGroup.Item><strong>Dirección del Predio: </strong>{data.data_predio.general[0].direccion_predio}</ListGroup.Item>
                                    <ListGroup.Item><strong>NPN: </strong>{data.data_predio.general[0].npn}</ListGroup.Item>
                                    <ListGroup.Item><strong>Inicio Vida Útil: </strong></ListGroup.Item>
                                    <ListGroup.Item><strong>Observaciones: </strong>{data.data_predio.general[0].observacion}</ListGroup.Item>
                                </ListGroup>
                                <ListGroup>
                                    <ListGroup.Item><strong>Fecha de Matrícula: </strong>{data.data_predio.interesados[0]?.fecha_matricula}</ListGroup.Item>
                                    <ListGroup.Item><strong>Número de Matrícula: </strong>{data.data_predio.interesados[0]?.numero_matricula}</ListGroup.Item>
                                    <ListGroup.Item><strong>Destinación Económica: </strong>{dataSearch?.desc_destecono}</ListGroup.Item>
                                    <ListGroup.Item><strong>Fin Vida Útil: </strong></ListGroup.Item>
                                </ListGroup>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Interesados data={data} selects={selects} />
                    <Terreno data={data} />
                    {
                        data.data_predio?.dibujo_terreno &&
                        <Card>
                            <Card.Body>
                                <Card.Title>Croquis</Card.Title>
                                <Image src={data.data_predio.dibujo_terreno.uri} fluid thumbnail />
                            </Card.Body>
                        </Card>
                    }
                    <Unidades dataConstrucciones={data.data_predio.unidades_construccion} selects={selects} construccionesType={construccionesType} />
                    <ContactoVisita data={data} />
                    <h6 className='mt-2'>Observaciones</h6>
                    <FormControl className='mt-2' as="textarea" rows={3} placeholder='Comentarios' value={observations || ""} onChange={handleChangeObservations} />
                    <div className='mt-2 d-flex justify-content-end' >
                        {
                            // eslint-disable-next-line eqeqeq
                            data.estado == 0 &&
                            <>
                                <Button variant="danger" className='mx-2' onClick={handleDecline} >Rechazar</Button>
                                <Button variant="success" className='mx-2' onClick={handleSuccess} >Aceptar</Button>
                            </>
                        }
                    </div>
                </Container>
            }
        </>
    );
};

export default ShowResume;
