import React, { useState, useEffect } from "react";
import {
  VStack,
  Text,
  HStack,
  Stack,
  Box,
} from "native-base";
// import PreFoto from "./PreFoto";
import { StyleSheet } from "react-native";
import { FotosTable } from "./FotosTable";

export const Calificaciones = ({data, construccionesTipo}) => {

  const [images, setImages] = useState({ estructura: [], banio: [], acabados: [], cocina: [],industria:[] })

  useEffect(() => {

    let copyImages = { estructura: [], banio: [], acabados: [], cocina: [],industria:[] }

    if(data.imagenes !== undefined) {
      data.imagenes.forEach(res => {
        res.forEach(r => {
          console.log(r.name.includes('estructura'))
          if(r.name.includes('estructura')) {
            copyImages.estructura.push(r)
          } else if(r.name.includes('banio')) {
            copyImages.banio.push(r)
          } else if(r.name.includes('acabadosp')) {
            copyImages.acabados.push(r)
          } else if(r.name.includes('cocina')) {
            copyImages.cocina.push(r)
          }else if(r.name.includes('industria')) {
            copyImages.industria.push(r)
          }
          
        })
      })
    }

    setImages(copyImages)
    
  }, [data])

  const nameConstruccionTipo = (id) => {
    if(construccionesTipo !== undefined) {
      const obj = construccionesTipo.find(res => res.id === id)
      const arrayName = obj.nombre.split(".");
      const arrName = arrayName[1].split("_")
      return arrName.join(" ")
    }
  }

  const searchValue = (array, keyCond, key, value) => {
    const obj = array.find(res => value === res[keyCond])
    return obj[key]
  }

  const nameConveciones = (id) => {
    if(id === 1) {
      return 'Malo'
    } else if(id === 2) {
      return 'Regular'
    } else if(id === 3) {
      return 'Bueno'
    } else if(id === 4) {
      return 'Excelente'
    } else {
      return 'Sin calificar'
    }
  }

  const searchScoreConvencionales = (id) => {
    if(id === 1) {
      return 0
    } else if(id === 2) {
      return 2
    } else if(id === 3) {
      return 4
    } else if(id === 4) {
      return 5
    } else {
      return 'N/A'
    }
  }

  return (
    <>
      <VStack space={3} width="100%">
        <HStack width="100%" display="flex" flexDirection="column">
         
          <HStack width="100%" display="flex" marginBottom={8}>
            <HStack style={styles.containerType} marginRight="1%">
              <Stack style={styles.contain} space={3} alignItems="center" direction="row" >
                <Text fontSize="md" fontWeight="bold">
                  Estructura
                </Text>
              </Stack>

              {
                data.calificacion.armazon !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConstruccionTipo(data.calificacion.armazon.id_objeto)}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.armazon.subtotal_estructura}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              }

              {
                data.calificacion.muros !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConstruccionTipo(data.calificacion.muros.id_objeto)}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.muros.subtotal_estructura}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              }

              {
                data.calificacion.cubierta !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConstruccionTipo(data.calificacion.cubierta.id_objeto)}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.cubierta.subtotal_estructura}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              }

              {
                data.cla_calificacion !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 1))}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 1)}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  data.cla_calif !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{nameConveciones(data.cla_calif.conservacion_est.id_estadoconser)}</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{searchScoreConvencionales(data.cla_calif.conservacion_est.id_estadoconser)}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>Sin calificar</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">N/A</Text>
                        </Box>
                      </Box>
                    </HStack>
                  )
                )
              }

              <HStack width="90%" marginTop={4} marginBottom={4}>
                 <HStack width="80%"  marginBottom={2}>
                    <Text>Fotos estructura</Text>
                  </HStack>
                <FotosTable
                  nombre={"Fotos Estructura"}
                  images={images.estructura}
                />
              </HStack>
            </HStack>

            <HStack style={styles.containerType}>
              {
                data.id_undconstipo !== 2 ? (
                  <Stack style={styles.contain} space={3} alignItems="center" direction="row">
                    <Text fontSize="md" fontWeight="bold">
                      Baños
                    </Text>
                  </Stack>
                ) : null
              }

              {
                data.id_undconstipo === 5 ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{data.calificacion.tamano_banos !== undefined ? nameConstruccionTipo(data.calificacion.tamano_banos.id_objeto) : "Sin calificar"}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.tamano_banos !== undefined ? data.calificacion.tamano_banos.subtotal_estructura : "N/A"}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : null
              }

              {
                data.id_undconstipo === 5 ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{data.calificacion.enchapes_ba !== undefined ? nameConstruccionTipo(data.calificacion.enchapes_ba.id_objeto) : "Sin calificar"}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.enchapes_ba !== undefined ? data.calificacion.enchapes_ba.subtotal_estructura : "N/A"}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : null
              }

              {
                data.id_undconstipo !== 2 ? (
                  data.calificacion.mobiliario_ba !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{nameConstruccionTipo(data.calificacion.mobiliario_ba.id_objeto)}</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{data.calificacion.mobiliario_ba.subtotal_estructura}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>Sin calificar</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">N/A</Text>
                        </Box>
                      </Box>
                    </HStack>
                  )
                ) : null
              }

              {
                data.id_undconstipo === 5  ? (
                  data.cla_calificacion !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 3))}</Text>
                      </HStack>
  
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 3)}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    data.cla_calif !== undefined ? (
                      <HStack style={styles.contain} display="flex" alignItems="center">
                        <HStack width="80%">
                          <Text>{nameConveciones(data.cla_calif.conservacion_ba.id_estadoconser)}</Text>
                        </HStack>
                        <Box width="20%" style={styles.boxO}>
                          <Box width="50%" style={styles.boxS}>
                            <Text textAlign="center">{searchScoreConvencionales(data.cla_calif.conservacion_ba.id_estadoconser)}</Text>
                          </Box>
                        </Box>
                      </HStack>
                    ) : (
                      <HStack style={styles.contain} display="flex" alignItems="center">
                        <HStack width="80%">
                          <Text>Sin calificar</Text>
                        </HStack>
                        <Box width="20%" style={styles.boxO}>
                          <Box width="50%" style={styles.boxS}>
                            <Text textAlign="center">N/A</Text>
                          </Box>
                        </Box>
                      </HStack>
                    )
                  )
                ) : null 
              }

              {
                data.id_undconstipo !== 2 ? (
                  <HStack width="90%" marginTop={4}>
                    <FotosTable
                      nombre={"Fotos Baño"}
                      images={images.banio}
                    />
                  </HStack>
                ) : null
              }
            </HStack>
          </HStack>

          <HStack width="100%" display="flex">
            <HStack style={styles.containerType} marginRight="1%">
              <Stack style={styles.contain} space={3} alignItems="center" direction="row">
                <Text fontSize="md" fontWeight="bold">
                  Acabados Principales
                </Text>
              </Stack>

              {
                data.calificacion.fachadas !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConstruccionTipo(data.calificacion.fachadas.id_objeto)}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.fachadas.subtotal_estructura}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              }

              {
                data.calificacion.cubrimiento_muros !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConstruccionTipo(data.calificacion.cubrimiento_muros.id_objeto)}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.cubrimiento_muros.subtotal_estructura}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              }

              {
                 data.calificacion.pisos !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{data.calificacion.pisos !== undefined ? nameConstruccionTipo(data.calificacion.pisos.id_objeto) : "Sin calificar"}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.pisos !== undefined ? data.calificacion.pisos.subtotal_estructura : "N/A"}</Text>
                      </Box>
                    </Box>
                  </HStack>
                 ) : (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>Sin calificar</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">N/A</Text>
                      </Box>
                    </Box>
                  </HStack>
                 )
              }

              {
                data.cla_calificacion !== undefined ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 2))}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 2)}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : (
                  data.cla_calif !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{data.cla_calif.conservacion_a !== undefined ? nameConveciones(data.cla_calif.conservacion_a.id_estadoconser) : "Sin calificar"}</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{data.cla_calif.conservacion_a !== undefined ? searchScoreConvencionales(data.cla_calif.conservacion_a.id_estadoconser) : "N/A"}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>Sin calificar</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">N/A</Text>
                        </Box>
                      </Box>
                    </HStack>
                  )
                )
              }

              <HStack width="90%" marginTop={4}>
                <FotosTable
                  nombre={"Foto Acabados Principales"}
                  images={images.acabados}
                />
              </HStack>
            </HStack>

            <HStack style={styles.containerType}>
              {
                data.id_undconstipo !== 2 ? (
                  <Stack style={styles.contain} space={3} alignItems="center" direction="row">
                    <Text fontSize="md" fontWeight="bold">
                      Cocina
                    </Text>
                  </Stack>
                ) : null
              }

              {
                data.id_undconstipo === 5 ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{data.calificacion.tamano_co !== undefined ? nameConstruccionTipo(data.calificacion.tamano_co.id_objeto) : "Sin calificar"}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.tamano_co !== undefined ? data.calificacion.tamano_co.subtotal_estructura : "N/A"}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : null
              }

              {
                data.id_undconstipo === 5 ? (
                  <HStack style={styles.contain} display="flex" alignItems="center">
                    <HStack width="80%">
                      <Text>{data.calificacion.enchapes_co !== undefined ? nameConstruccionTipo(data.calificacion.enchapes_co.id_objeto) : "Sin calificar"}</Text>
                    </HStack>
                    <Box width="20%" style={styles.boxO}>
                      <Box width="50%" style={styles.boxS}>
                        <Text textAlign="center">{data.calificacion.enchapes_co !== undefined ? data.calificacion.enchapes_co.subtotal_estructura : "N/A"}</Text>
                      </Box>
                    </Box>
                  </HStack>
                ) : null
              }

              {
                data.id_undconstipo !== 2 ? (
                  data.calificacion.mobiliario_co !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{nameConstruccionTipo(data.calificacion.mobiliario_co.id_objeto)}</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{data.calificacion.mobiliario_co.subtotal_estructura}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>Sin calificar</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">N/A</Text>
                        </Box>
                      </Box>
                    </HStack>
                  )
                ) : null
              }

              {
                data.id_undconstipo === 5  ? (
                  data.cla_calificacion !== undefined ? (
                    <HStack style={styles.contain} display="flex" alignItems="center">
                      <HStack width="80%">
                        <Text>{nameConveciones(searchValue(data.cla_calificacion, 'id_clacaltipo', 'id_estadoconser', 4))}</Text>
                      </HStack>
                      <Box width="20%" style={styles.boxO}>
                        <Box width="50%" style={styles.boxS}>
                          <Text textAlign="center">{searchValue(data.cla_calificacion, 'id_clacaltipo', 'valor_estadocon', 4)}</Text>
                        </Box>
                      </Box>
                    </HStack>
                  ) : (
                    data.cla_calif !== undefined ? (
                      <HStack style={styles.contain} display="flex" alignItems="center">
                        <HStack width="80%">
                          <Text>{nameConveciones(data.cla_calif.conservacion_co.id_estadoconser)}</Text>
                        </HStack>
                        <Box width="20%" style={styles.boxO}>
                          <Box width="50%" style={styles.boxS}>
                            <Text textAlign="center">{searchScoreConvencionales(data.cla_calif.conservacion_co.id_estadoconser)}</Text>
                          </Box>
                        </Box>
                      </HStack>
                    ) : (
                      <HStack style={styles.contain} display="flex" alignItems="center">
                        <HStack width="80%">
                          <Text>Sin calificar</Text>
                        </HStack>
                        <Box width="20%" style={styles.boxO}>
                          <Box width="50%" style={styles.boxS}>
                            <Text textAlign="center">N/A</Text>
                          </Box>
                        </Box>
                      </HStack>
                    )
                  )
                ) : null
              }

              {
                data.id_undconstipo !== 2 ? (
                  <HStack width="90%" marginTop={4}>
                    <FotosTable
                      nombre={"Foto Cocina"}
                      images={images.cocina}
                    />
                  </HStack>
                ) : null
              }
            </HStack>
          </HStack>

        </HStack>
      </VStack>

      

      {
        data.id_undconstipo === 2 ? (
          data.id_uso_cosnt >= 42 && data.id_uso_cosnt < 45 ? (
            <VStack space={3} width="100%">
              <HStack space={3} width="50%" display="flex" alignItems="center">
                <HStack width="80%">
                  <Text>{data.calificacion.cerchas_Complemento_Industria.id_objeto !== undefined ? nameConstruccionTipo(data.calificacion.cerchas_Complemento_Industria.id_objeto) : "Sin calificar"}</Text>
                </HStack>

                <Box width="20%" style={styles.boxO}>
                  <Box width="50%" style={styles.boxS}>
                    <Text textAlign="center">{data.calificacion.cerchas_Complemento_Industria.subtotal_estructura !== undefined ? data.calificacion.cerchas_Complemento_Industria.subtotal_estructura : "N/A"}</Text>
                  </Box>
                </Box>
              </HStack>

              <HStack space={3} width="50%">
                <HStack width="100%" alignSelf="center" mb={4}>
                <FotosTable
                        nombre={"Foto Industria:"}
                        images={images.industria}
                      />
                </HStack>
              </HStack>
            </VStack>
          ) : (
            (data.id_uso_cosnt === 40 || data.id_uso_cosnt === 41) && data.calificacion.cerchas_Complemento_Industria !== undefined ? (
              <VStack space={3} width="100%">
                <HStack space={3} width="50%" display="flex" alignItems="center">
                  <HStack width="80%">
                    <Text>{nameConstruccionTipo(data.calificacion.cerchas_Complemento_Industria.id_objeto)}</Text>
                  </HStack>

                  <Box width="20%" style={styles.boxO}>
                    <Box width="50%" style={styles.boxS}>
                      <Text textAlign="center">{data.calificacion.cerchas_Complemento_Industria.subtotal_estructura}</Text>
                    </Box>
                  </Box>
                </HStack>

                <HStack space={3} width="50%">
                  <HStack width="100%" alignSelf="center" mb={4}>
                  <FotosTable
                          nombre={"Foto Industria:"}
                          images={images.industria}
                        />
                  </HStack>
                </HStack>
              </VStack>
            ) : null
          )
        ) : null
      }

      {/* <HStack>
        <Box width="85%"></Box>
        <FormInput
          name="puntaje"
          label="TOTAL"
          control={control}
          typeInt={true}
        />
      </HStack> */}
    </>
  );
}

const styles = StyleSheet.create({
  containerType: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 8
  },
  contain: {
    width: "100%"
  },
  boxS: {
    borderWidth: 1,
    borderColor: "#c8e1ff",
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10,
  },
});
