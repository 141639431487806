import { Button, Card, Col, Container, Form, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { getListPrediosMySql, getReconocedoresMysql, postPrediosMySql } from '../../services/catastro_m2.service'
import ModalResponse from './components/load-predios/ModalResponse'

const LoadPredios = () => {

    const [reconocedores, setReconocedores] = useState(false)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [prediosMySql, setPrediosMySql] = useState([])
    const [reconocedor, setReconocedor] = useState("")
    const [file, setFile] = useState(false)
    const [responseUpload, setResponseUpload] = useState({ delete: [], duplicates: [], nonExistents: [] });


    useEffect(() => {
        const getReconocedores = async () => {
            setLoading(true)
            let response = await getReconocedoresMysql()
            const res = JSON.parse(response.data.data)
            const resSorted = res.map(data => ({ name: data.username, id: data.id }))
            setReconocedores(resSorted)
            setLoading(false)
        }

        const getPredios = async () => {
            let response = await getListPrediosMySql()
            const data = JSON.parse(response.data.data)
            const prediosMap = data.map((res) => res.npn)
            setPrediosMySql(prediosMap);
        }

        getPredios()
        getReconocedores()
    }, []);

    const handleFile = (e) => {
        const reader = new FileReader()
        if (e.target.files[0]) {
            reader.onload = async (e) => {
                const text = (e.target.result)
                setFile(text)
            };
            reader.readAsText(e.target.files[0])
        } else {
            setFile(false)
        }
    }

    const handleSubmit = async () => {
        Swal.fire('Por favor espere')
        Swal.showLoading()
        let response = null;
        let message = {}
        let copyResponseUpload = { ...responseUpload }
        let txtNpn = []
        if (file.includes('\r')) {
            const resNpn = file.split('\r\n');
            const npnClean = await clearArrayNpn(resNpn)
            txtNpn = npnClean
        } else {
            const resNpn = file.split('\n');
            const npnClean = await clearArrayNpn(resNpn)
            txtNpn = npnClean
        }

        if (txtNpn.length !== 0) {
            try {
                response = await postPrediosMySql({ predios: txtNpn, prediosMySql: prediosMySql, user: reconocedor })
                switch (response.status) {
                    case 250:
                        message = {
                            title: 'Se asignaron correctamente los predios.',
                            icon: 'success'
                        }
                        break;

                    case 254:
                        message = {
                            title: 'Solo se cargaron algunos predios.',
                            icon: 'success'
                        }
                        copyResponseUpload.duplicates = response.data.data.duplicates
                        copyResponseUpload.nonExistents = response.data.data.nonExistents
                        setModal(true)
                        break;

                    case 260:
                        message = {
                            title: 'Se asignaron los predios pero se elimino la asignacion a otros usuarios.',
                            icon: 'success'
                        }
                        copyResponseUpload.delete = response.data.data.delete
                        setModal(true)
                        break;

                    case 264:
                        message = {
                            title: 'Solo se cargaron algunos predios.',
                            icon: 'success'
                        }
                        copyResponseUpload.duplicates = response.data.data.duplicates
                        copyResponseUpload.delete = response.data.data.delete
                        copyResponseUpload.nonExistents = response.data.data.nonExistents
                        setModal(true)
                        break;

                    case 266:
                        message = {
                            title: 'No se cargo ningun predio.',
                            icon: 'error'
                        }
                        copyResponseUpload.duplicates = response.data.data.duplicates
                        copyResponseUpload.nonExistents = response.data.data.nonExistents
                        setModal(true)
                        break;

                    case 271:
                        message = {
                            title: 'No se cargo ningun predio.',
                            icon: 'error'
                        }
                        break;

                    case 272:
                        message = {
                            title: 'No se cargo ningun predio.',
                            icon: 'error'
                        }
                        copyResponseUpload.duplicates = response.data.data.duplicates
                        copyResponseUpload.nonExistents = response.data.data.nonExistents
                        setModal(true)
                        break;

                    case 273:
                        message = {
                            title: 'No se cargo ningun predio.',
                            icon: 'error'
                        }
                        break;

                    default:
                        message = {
                            title: 'No se cargo ningun predio.',
                            icon: 'error'
                        }
                        break;
                }
                setResponseUpload(copyResponseUpload)
                Swal.hideLoading()
                Swal.fire({
                    icon: message.icon,
                    title: message.title,
                    showConfirmButton: false,
                    timer: 2500
                })
            } catch (error) {
                Swal.hideLoading()
                Swal.fire({
                    icon: 'error',
                    title: "Hubo un error en la solicitud",
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        }else{
            Swal.hideLoading()
                Swal.fire({
                    icon: 'error',
                    title: "El archivo es inválido",
                    showConfirmButton: false,
                    timer: 2500
                })
        }
    }

    const clearArrayNpn = async (array) => {
        let copyArray = [...array]
        await array.forEach((res, i) => {
            if (res === "") {
                copyArray.splice(i, 1)
            }
        })
        return copyArray
    }


    return (
        <Container className="px-0">
            {modal &&
                <ModalResponse 
                    modal={modal}
                    data={responseUpload} 
                    setModal={setModal} 
                />
            }
            <Row>
                <Col xs={12} className="p-3">
                    <Card>
                        <Card.Header>
                            <h2 className="h2" >Cargar Predios Visita</h2>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Reconocedor</Form.Label>
                                        <Form.Select
                                            value={reconocedor || ""}
                                            onChange={e => setReconocedor(e.target.value)}
                                        >
                                            <option value="">Todos</option>
                                            {
                                                !loading &&
                                                reconocedores.map((reconocedor, index) => (
                                                    <option key={reconocedor.name + index} value={reconocedor.id}>{reconocedor.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Archivo</Form.Label>
                                        <Form.Control
                                            type='file'
                                            accept=".txt"
                                            onChange={handleFile}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    variant='success'
                                    disabled={!file}
                                    onClick={handleSubmit}
                                >Cargar Archivo
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default LoadPredios