
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import AuthService from "../../services/auth.service";
import MailService from "../../services/mail.service";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const [userData, setUserData] = useState({ username: "", email: "", password: "", password2: "", terms: "" });
  const [btns, setBtns] = useState({ username: false, email: false, password: false, password2: false, terms: false });
  const [errorMessage, setErrorMessage] = useState({ value: "" });

  const history = useHistory();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setUserData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

  };

  const handleInputOnKeyUp = (e) => {
    const { name, value } = e.target;
    //console.log(btns);

    setBtns((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (userData.username === "" || userData.email === "" || userData.password === "" || userData.password2 === "" || userData.terms === false) {
      setErrorMessage((prevState) => ({
        type: 1, value: "Campo vacíos",
      }));
      return;
    }

    if (userData.password !== userData.password2 ) {
      setErrorMessage((prevState) => ({
        type: 1, value: "Las contraseñas no son iguales",
      }));
      return;
    }

    if (userData.password !== userData.password2 ) {
      setErrorMessage((prevState) => ({
        type: 1, value: "Las contraseñas no son iguales",
      }));
      return;
    }

    setErrorMessage((prevState) => ({ type: 3, value: "Loading" }));

    AuthService.register(userData).then(
      async (response) => {

          if( response ) {

            setTimeout(function(){
              
              // eslint-disable-next-line no-unused-expressions
              MailService.sendEmail({
                title: 'Valle avanza',
                email: userData.email,
                subject: 'Registro de usuario exitoso',
                html: ``
              }).then((response) => {
                  //console.log(response);
              // eslint-disable-next-line no-sequences
              }), (error) => {
                  const _error =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
          
                    setErrorMessage((prevState) => ({ type:1, value: _error }));
          
              };

              setErrorMessage((prevState) => ({ type:2, value:"Registro correcto" }));
              
            },1000)
            setTimeout(function(){
              history.push("/");
            },2000)
          } else {
            setErrorMessage((prevState) => ({ type:1, value:"No se pudo registrar el usuario" }));
          }
      },
      (error) => {
          setErrorMessage((prevState) => ({ type:1, value: error.response.data.message }));
      }
    );
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Login.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Regresar
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Registrarse</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="username" className="mb-4">
                    <Form.Label>Usuario</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.username !== false ? btns.username === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="username" type="text" placeholder="Ejemplo: Felipe" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite el usuario.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Correo</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.email !== false ? btns.email === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="email" type="email" placeholder="Ejemplo: ejemplo@google.com" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite el correo.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.password !== false ? btns.password === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="password" type="password" placeholder="Contraseña" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite la nueva contraseña.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirmar Contraseña</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required className={btns.password2 !== false ? btns.password2 === "" ? "is-invalid" : "is-valid" : ""} autoFocus name="password2" type="password" placeholder="Confirmar Contraseña" onChange={(e) => handleInputChange(e)} onKeyUp={(e) => handleInputOnKeyUp(e)} />
                      <Form.Control.Feedback type="invalid">Por favor, digite nuevamente la constraseña.</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required className={btns.terms !== false ? btns.terms === "" ? "me-2 is-invalid" : "me-2 is-valid" : "me-2"} autoFocus name="terms" id="terms" />
                    <FormCheck.Label htmlFor="terms">
                    Acepto los <Card.Link as={Link} to={Routes.Terminos.path} className="fw-bold">términos y condiciones</Card.Link>
                    </FormCheck.Label>
                    <Form.Control.Feedback type="invalid">Por favor, acepte los términos y condiciones.</Form.Control.Feedback>
                  </FormCheck>
                  {errorMessage.value && ( errorMessage.type === 1 ?
                    <div style={{textAlign:'center'}}><p className="text-danger"> {errorMessage.value} </p></div> : errorMessage.type === 2 ?
                    <div style={{textAlign:'center'}}><p className="text-success"> {errorMessage.value} </p></div> : <div style={{textAlign:'center', marginBottom: 20}}><Spinner animation="border" size="sm" /></div>
                  ) }
                  <Button variant="primary" type="submit" className="w-100">
                    Registrar
                  </Button>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Login.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
