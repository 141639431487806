import React, {useState, useEffect} from 'react'
import {
  Button,
  Center,
  HStack,
  Modal,
  View,
  Text
} from 'native-base';
import { StyleSheet, TouchableOpacity} from 'react-native';
import { Calificaciones } from './Calificaciones'
import { FotosTable } from './FotosTable';

export const RowConstrucciones = ({rowData, index, data, construccionesTipo, selects}) => {
    
    const [modal, setModal] = useState(false);
    const [images, setImages] = useState([])
    const [codigo, setCodigo] = useState("")

    useEffect(() => {

        let resImages = []

        if(data.imagenes !== undefined) {
            data.imagenes.forEach(res => {
                res.forEach(r => {
                    resImages.push(r)
                })
            })
            setImages(resImages)
        }
        const arr = data.destino.split(".")
        setCodigo(arr[1])
    }, [data.destino, data.imagenes, rowData])

    const showModal = () => {
        setModal(true)
        console.log(data.tipo_construccion, '<><><><><<<<<<<<')
    }

    const btn = () => (
        <Center>
            {
                data.retirado === 2 ? (
                    <View>
                        <Text> Retirado </Text>
                    </View>
                ) : (
                    data.calificacion !== undefined ? (
                        <TouchableOpacity onPress={() => showModal()}>
                            <View style={styles.btn}>
                                <Text style={styles.btnText}> Ver </Text>
                            </View>
                        </TouchableOpacity>
                    ) : data.tipo_construccion !== 'Convencional' ? (
                        <TouchableOpacity onPress={() => showModal()}>
                            <View style={styles.btn}>
                                <Text style={styles.btnText}> Ver </Text>
                            </View>
                        </TouchableOpacity>
                    ) : (
                        <View>
                            <Text> Sin calificacion </Text>
                        </View>
                    )
                )
            }
        </Center>
    )

    const nameConstruccionTipo = (id) => {
        if(selects.anexo_tipo !== undefined) {
            const obj = selects.anexo_tipo.find(res => res.id === id)
            if(obj.nombre !== undefined) {
                const arrayName = obj.nombre.split(".");
                const arrName = arrayName[1].split("_")
                return arrName.join(" ")
            } else {
                return "N/A"
            }
        }
    }

  return (
    <>
        <th key={index} style={styles.row}>
            {
                rowData.map((cellData, cellIndex) => {

                    return (
                        (rowData.length - 1) === cellIndex ? (
                            <th 
                                key={cellIndex} 
                                data={btn()} 
                                textStyle={styles.textData}
                            />
                        ) : (
                            <th 
                                key={cellIndex} 
                                data={cellData} 
                                textStyle={styles.textData}
                            />
                        )
                    )
                })
            }
        </th>
        {
            data.calificacion !== undefined || data.tipo_construccion === 'No convencional' ? (
                <Modal isOpen={modal} onClose={() => setModal(false)}>
                    <Modal.Content maxWidth="80%" maxHeight="90%">
                        <Modal.CloseButton />
                        <Modal.Header>Calificaciones</Modal.Header>
                        <Modal.Body>
                            {
                                data.tipo_construccion === 'Convencional' ? (
                                    <>
                                        <HStack width="100%" marginBottom={8}>
                                            <Text width="100%">
                                                <Text bold>Observaciones:</Text>{" "}
                                                {data.observaciones}
                                            </Text>
                                        </HStack>
                                        <Calificaciones 
                                            data={data}
                                            construccionesTipo={construccionesTipo}
                                        />
                                    </>
                                ) : (
                                    <HStack space={3} width="80%" ml="10%" display="flex" flexDirection="column">
                                        {
                                            data.id_undconstipo === 4 && (
                                                <HStack width="100%" marginBottom={8}>
                                                    <Text width="50%">
                                                        <Text bold>Codigo:</Text>{" "}
                                                        {codigo}
                                                    </Text>
                                                    <Text width="50%">
                                                        <Text bold>Tipificación:</Text>{" "}
                                                        {data.id_anexotipo != null && data.id_anexotipo !== undefined && data.id_anexotipo !== "" ? nameConstruccionTipo(data.id_anexotipo) : ""}
                                                    </Text>
                                                </HStack>
                                            )
                                        }
                                        <HStack width="100%" marginBottom={8}>
                                            <Text width="100%">
                                                <Text bold>Observaciones:</Text>{" "}
                                                {data.observaciones}
                                            </Text>
                                        </HStack>
                                        <HStack width="100%" alignSelf="center" mb={4}>
                                            <FotosTable
                                                images={images}
                                            />
                                        </HStack>
                                    </HStack>
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group variant="ghost" space={1}>
                                <Button onPress={() => setModal(false)}>Cerrar</Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            ) : null
        }
    </>
  )
}


const styles = StyleSheet.create({
  textData: { margin: 6, textAlign: "center" },
  row: { flexDirection: 'row'},
  btn: {
    width: 58,
    height: 22,
    backgroundColor: "#78B7BB",
    borderRadius: 4,
    textAlign: "center",
    color: "#fff"
  },
  btnText: { textAlign: "center", color: "#fff" },
});