import { Button, Modal } from '@themesberg/react-bootstrap'
import React from 'react'

const ModalResponse = ({ data, setModal, modal }) => {
    return (
        <Modal as={Modal.Dialog} centered show={modal} onHide={() => { setModal(false) }}>
            <Modal.Header>
                <Modal.Title className="h6">Resumen de carga y asignacion de predios</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={() => { setModal(false) }} />
            </Modal.Header>
            <Modal.Body>
                {data.duplicates.length > 0 && (
                    <>
                    <h6>Los siguientes predios estan duplicados</h6>
                    {
                        data.duplicates.map(res => (
                            <p key={res}>{res}</p>
                        ))
                    }
                    </>
                )}
                {data.delete.length > 0 && (
                    <>
                    <h6>Los siguientes predios se eliminaron a otros reconocedores</h6>
                    {
                        data.delete.map(res => (
                            <p>{res}</p>
                        ))
                    }
                    </>
                )}
                {data.nonExistents.length > 0 && (
                    <>
                    <h6>Los siguientes predios no existen</h6>
                    {
                        data.nonExistents.map(res => (
                            <p>{res}</p>
                        ))
                    }
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ModalResponse