import React, { useState, useEffect } from "react";
import { NativeBaseProvider, Center, Button, useToast, VStack, Modal, Select, HStack, Box, Icon, Input } from "native-base";
import { getBorradores, setFullDataPredio, updateEstadoBorradores, getReconocedoresMysql } from "../../services/catastro_m2.service";
import { BorradoresTable } from './components/BorradoresTable';
import { selects_calif_correcta } from '../../services/catastro_construcciones';
import CatastroService from '../../services/catastro.service';
import CatastroServiceJ from '../../services/catastro_j.service';
import { ResumenBorrador } from "./components/ResumenBorrador";
import mailService from "../../services/mail.service";

import { getBorradoresConservation, updateEstadoBorradoresConservation } from "../../services/catastro_m1.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({isConservation = false}) => {

    //const window = Dimensions.get('window');
    //let 500 = window.height-(window.height*0.40);

    const toast = useToast();

    const [borradores, setBorradores] = useState([])
    const [borradoresWithoutPagination, setBorradoresWithoutPagination] = useState([])
    const [borradoresOriginal, setBorradoresOriginal] = useState([])
    const [construccionesTipo, setConstruccionesTipo] = useState([])
    const [users, setUsers] = useState([])
    const [selects, setSelects] = useState([])

    const [reconocedores, setReconocedores] = useState([])
    const [reconocedor, setReconocedor] = useState('all')
    const [npn, setNpn] = useState('')

    const [pagination, setPagination] = useState({initial: 1, end: 10})
    const [hide, setHide] = useState({next: true, previous: false})
    const [counter, setCounter] = useState(0)

    const [resumen, setResumen] = useState({data: {}, index: 0})

    const [modal, setModal] = useState(false)

    const [loadingInitial, setLoadingInitial] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
          let response = {}
          try {
            if(isConservation) {
              response = await getBorradoresConservation()
            }else {
              response = await getBorradores()
            }
            const data = JSON.parse(response.data.data)
            const resData = data.map((res, index) => ({...res, data_predio: JSON.parse(res.data_predio), index: index}))
            let copyCounter = 0
            const resDataLimited = resData.filter((res, index) => {
              copyCounter++
              return (index + 1) < pagination.end
            })
            setCounter(copyCounter)
            setBorradores(resDataLimited)
            setBorradoresOriginal(resData)
            setBorradoresWithoutPagination(resData)
            setLoadingInitial(true)
          } catch (error) {
            setLoadingInitial(true)
          }
          
        }

        const fetchDataConstruccionesTipo = () => {
          selects_calif_correcta()
            .then(
              (response) => {
                const dataApiSelects = response.data.data;
                setConstruccionesTipo(dataApiSelects)
              },
              (err) => {
                console.log(err)
              }
            );
            CatastroServiceJ.getSelects()
              .then(
                (response) => {
                  const data = response.data.data[0];
                  setSelects(data)
                },
                (err) => {
                  console.log(err)
                }
              )
        }

        const fetchUsers = () => {
          CatastroService.getListUser()
            .then(
              (response) => {
                setUsers(response.data)
              },
              (err) => {
                console.log(err)
              }
            );
        }

        const fetchDataUsers = () => {
          getReconocedoresMysql().then(
            (response) => {
              const res = JSON.parse(response.data.data)
              const resSorted = res.map(data => ({nombre: data.username, id: data.id}))
              setReconocedores(resSorted)
            },
            (error) => {
              console.log(error);
            }
          );
        }

        fetchData()
        fetchDataConstruccionesTipo()
        fetchUsers()
        fetchDataUsers()
    }, [isConservation, pagination.end])

    useEffect(() => {
      let copyHide = {...hide}
      if(borradoresWithoutPagination.length >= 11) {
        copyHide.next = true
        setHide(copyHide)
      } else {
        copyHide.next = false
        setHide(copyHide)
      }
    }, [borradoresWithoutPagination, hide])

    useEffect(() => {
      filterWithoutPagination()
      setPagination({initial: 1, end: 10})
      setHide({next: true, previous: false})
    // eslint-disable-next-line no-use-before-define
    }, [reconocedor, npn, filterWithoutPagination])

    useEffect(() => {
      if(counter > 0) {
        filter()

        const copyHide = {...hide}
        if(pagination.initial <= 0) {
          copyHide.previous = false
        } else {
          copyHide.previous = true
        }
        if(pagination.end >= counter) {
          copyHide.next = false
        } else {
          copyHide.next = true
        }
        if(borradoresWithoutPagination.length >= 11) {
          copyHide.next = true
          setHide(copyHide)
        } else {
          copyHide.next = false
          setHide(copyHide)
        }
        if(borradoresWithoutPagination.length >= pagination.end) {
          copyHide.next = true
          setHide(copyHide)
        } else {
          copyHide.next = false
          setHide(copyHide)
        }

        setHide(copyHide)
      }
    // eslint-disable-next-line no-use-before-define
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filter = () => {
      if(reconocedor !== '') {
        if(reconocedor === 'all') {
          if(npn !== '') {
            const filterBorrador = borradoresWithoutPagination.filter((res, index) => (index + 1) >= pagination.initial && (index + 1) <= pagination.end && res.data_predio.general[0].npn.includes(npn))
            setBorradores(filterBorrador)
          } else {
            const filterBorrador = borradoresWithoutPagination.filter((res, index) => (index + 1) >= pagination.initial && (index + 1) <= pagination.end)
            setBorradores(filterBorrador)
          }
        } else {
          if(npn !== '') {
            const filterBorrador = borradoresWithoutPagination.filter((res, index) => res.user_id === reconocedor && (index + 1) >= pagination.initial && (index + 1) <= pagination.end && res.data_predio.general[0].npn.includes(npn))
            setBorradores(filterBorrador)
          } else {
            const filterBorrador = borradoresWithoutPagination.filter((res, index) => res.user_id === reconocedor && (index + 1) >= pagination.initial && (index + 1) <= pagination.end)
            setBorradores(filterBorrador)
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function filterWithoutPagination() {
    if (reconocedor !== '') {
      if (reconocedor === 'all') {
        if (npn !== '') {
          const filterBorrador = borradoresOriginal.filter((res, index) => res.data_predio.general[0].npn.includes(npn));
          setBorradoresWithoutPagination(filterBorrador);
        } else {
          setBorradoresWithoutPagination(borradoresOriginal);
        }
      } else {
        if (npn !== '') {
          const filterBorrador = borradoresOriginal.filter((res, index) => res.user_id === reconocedor && res.data_predio.general[0].npn.includes(npn));
          setBorradoresWithoutPagination(filterBorrador);
        } else {
          const filterBorrador = borradoresOriginal.filter((res, index) => res.user_id === reconocedor);
          setBorradoresWithoutPagination(filterBorrador);
        }
      }
    }
  }

    const next = () => {
      let copyPagination = {...pagination}
      copyPagination.initial += 10
      copyPagination.end += 10
      setPagination(copyPagination)
    }

    const previous = () => {
      let copyPagination = {...pagination}
      copyPagination.initial -= 10
      copyPagination.end -= 10
      setPagination(copyPagination)
    }

    const handleChangeNpn = (text) => {
      setNpn(text)
    }

    const showToastRejected = () => {
      
    }

    const getEmail = (id) => {
      const obj = users.find(res => id === res.id)
      return obj.email
    }

    const handleChangeObservations = (value, index) => {
      let newValue = value;
      newValue = newValue.replace(/(?:\r\n|\r|\n)/g, ' ');

      newValue = newValue.replace(/"([^"]*)"/g, function (m,g) {
          return g.replace(/\b([\r\n]+)\b|[\r\n]+/g, function(n,h) {
              return h ? ' ' : '';
          });
      });
      let copyData = [...borradores]
      if(copyData[index].data_predio?.observaciones) {
        copyData[index].data_predio.observaciones = newValue
      }
      copyData[index].observaciones = newValue
      setBorradores(copyData)

      let copyResumen = {...resumen}
      if (copyResumen.data.data_predio?.observaciones) {
        copyResumen.data.data_predio.observaciones = newValue
      }
      copyResumen.data.observaciones = newValue
      setResumen(copyResumen)
    }

    const deletePredioVerified = () => {
      let copyData = [...borradores]
      let copyDataOriginal = [...borradoresOriginal]
      copyData.splice(resumen.index, 1)
      copyDataOriginal.splice(resumen.data.index, 1)
      setBorradores(copyData)
      setBorradoresOriginal(copyDataOriginal)
    }

    const getResumen = (data, index) => {
      const obj = {data: data, index: index}
      setResumen(obj)
      setModal(true)
    }

    const submit = async(estado, email) => {

      //const reject = resumen.data.data_predio.unidades_construccion.some(element => element.area_construida === "")
      //const retirado = resumen.data.data_predio.unidades_construccion.some(element => element.retirado === 2);

      let reject = false;

      reject = resumen.data.data_predio.unidades_construccion.some(element => element.retirado !== 2 && element.area_construida === "")

      if( estado === 2 ) {
        reject = false;
      }

      if(reject) {
        toast.show({
          title: "Error aprobando!",
          description: "Area construida vacia",
          status: "error"
        })
        setModal(false)
      } else {
        //let response = {}
        if(isConservation) {
          updateEstadoBorradoresConservation(resumen.data.id, estado, {observaciones: resumen.data.observaciones}, resumen.data.data_predio.general[0].npn)
        }else {
          updateEstadoBorradores(resumen.data.id, estado, {observaciones: resumen.data.observaciones}, resumen.data.data_predio.general[0].npn)
          try {
            if(estado === 1) {
              setFullDataPredio(resumen.data.data_predio).then(
                  (res) => {
                    // eslint-disable-next-line no-unused-expressions
                    mailService.sendEmail({
                      title: 'Valle avanza',
                      email: getEmail(resumen.data.user_id),
                      subject: `Estado NPN:${resumen.data.data_predio.general[0].npn}`,
                      html: `
                        <div>
                          <h3 style={{margin-bottom: 2rem}}>Buen dia</h3>
                          <p style={{margin-bottom: 2rem}}>El borrador con npn ${resumen.data.data_predio.general[0].npn} ha sido aprobado.</p>
                          <p>Gracias</p>
                        </div>
                      `
                    }).then((responseMail) => {
                        console.log(responseMail);
                    // eslint-disable-next-line no-sequences
                    }), (error) => {
                      console.log(error)
                    };

                    toast.show({
                      title: "Verificado!",
                      status: "success",
                      description: res.data.data,
                      isClosable: true,
                      duration: 30000
                    })
                  },
                  (err) => {
                    console.log(err)
                  }
              );
            } else {
              // eslint-disable-next-line no-unused-expressions
              mailService.sendEmail({
                title: 'Valle avanza',
                email: getEmail(resumen.data.user_id),
                subject: `Borrador con npn ${resumen.data.data_predio.general[0].npn} fue rechazado`,
                html: `
                  <div>
                    <h3 style={{margin-bottom: 2rem}}>Buen dia</h3>
                    <p style={{margin-bottom: 2rem}}>El borrador con npn ${resumen.data.data_predio.general[0].npn} ha sido rechazado. Debe entrar a la aplicación móvil  en la sección editar y revisar los detalles de la no aprobación.</p>
                    <p>Gracias</p>
                  </div>
                `
              }).then((responseMail) => {
                  console.log(responseMail);
              // eslint-disable-next-line no-sequences
              }), (error) => {
                console.log(error)
              };
              toast.show({
                title: "Rechazado!",
                status: "error"
              })
            }
            setModal(false)
            deletePredioVerified(resumen.data.index)
          } catch (error) {
            toast.show({
              title: "Error!",
              status: "error",
              description: "No se pudo validar, intentar mas tarde"
            })
            console.log(error)
            setModal(false)
            showToastRejected()
            deletePredioVerified(resumen.data.index)
          }
        }
      }
  }

    return (
      <NativeBaseProvider>
        <HStack display="flex" flexDirection="column">
          <HStack display="flex" justifyContent="space-around">
            <VStack width="40%" space={2} >
              <Box>Reconocedores</Box>
              <Select
                selectedValue={reconocedor}
                onValueChange={(value) => setReconocedor(value)}
                selectedItemBg={"teal.400"}
                placeholder={'Seleccionar reconocedor'}
                dropdownOpenIcon={
                  <Icon
                    size={6}
                  />
                }
                dropdownCloseIcon={
                  <Icon
                    size={6}
                  />
                }
              >
                <Select.Item label="Todos" value="all" />
                {
                  reconocedores.map((item) => (
                    <Select.Item textTransform='capitalize' label={item.nombre} key={item.id} value={item.id} />
                  ))
                }
              </Select>
            </VStack>

            <VStack width="20%" space={2}>
              <Box>NPN</Box>
              <Input
                placeholder="NPN"
                variant="filled"
                width="100%"
                bg="gray.200"
                borderRadius={10}
                value={npn}
                py={1}
                px={2}
                maxLength={30}
                _web={{
                  _focus: { borderColor: 'muted.300', style: { boxShadow: 'none' } },
                  }}
                onChangeText={(text) => handleChangeNpn(text)}
              />
            </VStack>
          </HStack>
          
          <VStack width="100%" marginTop={4}>
            <BorradoresTable 
              hide={hide}
              pagination={pagination}
              counter={counter}
              next={next}
              previous={previous}
              borradores={borradores}
              getResumen={getResumen}
              loadingInitial={loadingInitial}
            />
          </VStack>
        </HStack>
        <Center height={500} width="100%">
          <Modal isOpen={modal} onClose={() => setModal(false)}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>Resumen</Modal.Header>
                <Modal.Body>
                    <ResumenBorrador 
                      data={resumen.data} 
                      index={resumen.index} 
                      handleChangeObservations={handleChangeObservations} 
                      construccionesTipo={construccionesTipo} 
                      selects={selects} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group variant="ghost" space={2}>
                        <Button onPress={() => submit(2)}>Rechazar</Button>
                        <Button onPress={() => submit(1)}>Aceptar</Button>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
        </Center>

      </NativeBaseProvider>
    );
  }