import { Image, Row } from '@themesberg/react-bootstrap';
import React from 'react';
import { useState } from 'react';

const FotosModal = ({images}) => {
    console.log(images)
    var validateClick = (event) => {   
      var validate = event.target.classList.contains('hover-zoom');

      if( !validate ) {
        event.target.classList.remove('normal');
        event.target.classList.add('hover-zoom');
      } else {
        event.target.classList.add('normal');
        event.target.classList.remove('hover-zoom');
      }
    };

    return (
      <>
        <Row md={2}>
            {images.map((image, index) => (
                  <Image key={image.filename} src={image.route} fluid thumbnail className='normal' onClick={(event) => 
                    validateClick(event)} />
            ))}
        </Row>
      </>
  );
};

export default FotosModal;
