
//import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';

//import NOTIFICATIONS_DATA from "../data/notifications";
import UserFoto from "../assets/img/team/user.png";

//import Users from "../../core/Users";

import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../routes";

import AuthService from "../services/auth.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {

  const history = useHistory();

  const dataUser = AuthService.getCurrentUser();
  let ReUsername = dataUser.username.toLowerCase();
  ReUsername = ReUsername.charAt(0).toUpperCase() + ReUsername.slice(1);
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map(n => ({ ...n, read: true })));
  //   }, 300);
  // };


  // const Notification = (props) => {
  //   const { link, sender, image, time, message, read = false } = props;
  //   const readClassName = read ? "" : "text-danger";

  //   return (
  //     <ListGroup.Item action href={link} className="border-bottom border-light">
  //       <Row className="align-items-center">
  //         <Col className="col-auto">
  //           <Image src={image} className="user-avatar lg-avatar rounded-circle" />
  //         </Col>
  //         <Col className="ps-0 ms--2">
  //           <div className="d-flex justify-content-between align-items-center">
  //             <div>
  //               <h4 className="h6 mb-0 text-small">{sender}</h4>
  //             </div>
  //             <div className="text-end">
  //               <small className={readClassName}>{time}</small>
  //             </div>
  //           </div>
  //           <p className="font-small mt-1 mb-0">{message}</p>
  //         </Col>
  //       </Row>
  //     </ListGroup.Item>
  //   );
  // };

  //const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getAllPosts());
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
          </div>
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={UserFoto} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{ReUsername}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item>

                <Dropdown.Divider /> */}

                <Dropdown.Item className="fw-bold" as={Link} to={Routes.ResetearContrasenaAdmin.path}>
                  <FontAwesomeIcon icon={faUserCircle} className="text-danger me-2" /> Cambiar contraseña
                </Dropdown.Item>
                
                <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar Sesión
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
