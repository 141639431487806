import { Col, Form, Row } from '@themesberg/react-bootstrap';
import React from 'react';
import { useBorradores } from '../../../../context/borradores/BorradoresContext';




const CityStatus = ({column}) => {
const { tBody, tBodySearched, modoBusqueda, limpiarBusqueda, buscarDatosBorradores, valoresBusqueda, listaBorradores, loading, sizePage } = useBorradores();
// console.log("que contienee",tBodySearched);
// console.log("que tienee",tBody);
// console.log("que es",typeof(tBodySearched));
let body_t= Object.keys(tBodySearched).length === 0 ? tBody:tBodySearched;

// console.log("body_t",body_t);
let test=[];
let claves = Object.keys(body_t); 
for(let i=0; i< claves.length; i++){
let clave = claves[i];

test.push(body_t[clave].city);
}
const Municipios = new Set(test);
// console.log("Data Muni",Municipios);
    const {filterValue, setFilter} = column
    let SelectMunicipios = [];
       
    Municipios.forEach((myitem, key) => {
        SelectMunicipios.push(<option key={key} value={myitem}>{myitem}</option>)
    
    })

    SelectMunicipios.push(<option key={SelectMunicipios.length} value="">Todos</option>)

    return (
        <Row>
            <Col xs={100}>
                <Form.Select
                    value={filterValue || ""}
                    onChange={e => setFilter(e.target.value)}
                    size='sm'
                >
                {SelectMunicipios}
                    
                
                </Form.Select>
                
            </Col>
        </Row>
    );
};

export default CityStatus;
