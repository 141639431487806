export default function orderData(data, dataHeaders){
  let totalArr = [];
  // console.log(data)

  data.forEach(function(item){
    let newArr = [];
    dataHeaders.forEach(function(header){
      let flag_found = 0
      for (const property in item) {
        if(property === header){
          newArr.push(item[property])
          flag_found = 1
        }
      }
      if(flag_found === 0){
        newArr.push("")
      }
    })
    totalArr.push(newArr)
  })

  return totalArr
}