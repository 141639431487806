import { Card, Table } from '@themesberg/react-bootstrap';
import React, {useEffect, useState} from 'react';

const Interesados = ({data, selects}) => {

    const [interesados, setInteresados] = useState([]);

    useEffect(() => {
        if (selects?.tipod_documento) {
            if( typeof data.data_predio.interesado !== 'undefined' ) {
                const interesadosTemp = data.data_predio.interesado.map(resp => ({
                    type: selects.tipod_documento.find((a) => a.id == resp.id_tipodoc), 
                    document: resp.documento_identidad, 
                    name: resp.primer_nombre !== '' ? `${resp.primer_nombre} ${resp.segundo_nombre !== null ? resp.segundo_nombre : ''} ${resp.primer_apellido} ${resp.segundo_apellido !== null ? resp.segundo_apellido : ''}` : resp.razon_social, 
                    start_life: resp.comienzo_vida_util, 
                    end_life: resp.fin_vida_util
                }))
                setInteresados(interesadosTemp)
            }
        }
    }, [selects]);
    

  return (
        <Card>
            <Card.Body>
                <Card.Title>Información de Interesados</Card.Title>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Tipo de Id</th>
                            <th># de Id</th>
                            <th>Nombres</th>
                            <th>Inicio Vida útil</th>
                            <th>Fin Vida útil</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            interesados.map((interesado, index) => (
                                <tr key={index}>
                                    {
                                        interesado?.type?.nombre ? (<td>{interesado.type.nombre}</td>) : (<td>Sin tipo de documento</td>)
                                    }
                                    <td>{interesado.document}</td>
                                    <td>{interesado.name}</td>
                                    <td>{interesado.start_life}</td>
                                    <td>{interesado.end_life}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
  );
};

export default Interesados;
