import React, { useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import { Image, Center, Modal, Button } from "native-base";

export const Img = ({ data, style, bigStyle }) => {

    const [bigImage, setBigImage] = useState(false)

    const showBigImage = () => { setBigImage(true)}

    return (
        <>
            <View style={styles.container}>
                <TouchableOpacity onPress={() => showBigImage()}>
                    <Image
                        alt="2"
                        source={{ uri: data }}
                        style={style}
                    />
                </TouchableOpacity>
                <Modal isOpen={bigImage} onClose={() => setBigImage(false)}>
                    <Modal.Content maxWidth="80%" maxHeight="90%">
                        <Modal.CloseButton />
                        <Modal.Header>Imagen</Modal.Header>
                        <Modal.Body>
                            <Center>
                                <Image
                                    alt="2"
                                    source={{ uri: data }}
                                    style={bigStyle}
                                />
                            </Center>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group variant="ghost" space={1}>
                                <Button onPress={() => setBigImage(false)}>Cerrar</Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: { flex: 1, padding: 4, backgroundColor: "#fff" }
});
