import axios from "axios";
import config from "../config";

const API_URL = config.API_URL;

export function selects_calificacion() {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.get(
        API_URL + `catastro/construcciones/selects_calificacion`
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}

export function selects_calif_correcta() {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.get(
        API_URL + `catastro/construcciones/selects_calif_correcta`
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}

export function selects_conservacion() {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.get(
        API_URL + `catastro/construcciones/selects_conservacion`
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}


export function insertConstruccion(body) {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.post(
        API_URL + `catastro/construcciones/insert_construccion`,
        body
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}

export function updateConstruccion(body, id) {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.post(
        API_URL + `catastro/construcciones/update_construccion/${id}`,
        body
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}
export function updatePictures(body, id) {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.post(
        API_URL + `catastro/construcciones/update_pictures/${id}`,
        body
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}
export function updateCalificacionClaca(body, id) {
  return new Promise(async function (resolve, reject) {
    try {
      let data = await axios.post(
        API_URL + `catastro/construcciones/update_calificacion_claca/${id}`,
        body
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });}
  export function updateCalificacionConv(body, id) {
    return new Promise(async function (resolve, reject) {
      try {
        let data = await axios.post(
          API_URL + `catastro/construcciones/update_calificacion_conv/${id}`,
          body
        );
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });}