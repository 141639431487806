import React, {useState, useEffect} from 'react'
import {
  View,
  Text
} from 'native-base';
import { Table, Row } from '@themesberg/react-bootstrap';
import { StyleSheet} from 'react-native';
import orderData from '../../../../helpers/orderData';

export const ContactoTable = ({dataContactos}) => {
  const tableHead = ['Tipo de identificación', 'Número de identificación', 'Nombre Contacto'];
  // const tableData = [
  //   ['CC', '10032054', 'PEPITO PÉREZ', '15/02/2017', ''],
  //   ['CC', '10032055', 'PEPITO PÉREZ', '15/02/2017', ''],
  //   ['CC', '10032056', 'PEPITO PÉREZ', '15/02/2017', ''],
  // ];
  const [contactos, setContactos] = useState([]);

  useEffect(()=> {
    const dataHeaders = ["des_tipodoc", "num_doc_atendio", "nombre"];
    const dataTable = orderData(dataContactos, dataHeaders)
    setContactos(dataTable)
  }, [dataContactos])

  return (
    <View bg="white" >
      <Text mx={5} my={2}>Información de Contacto Visita</Text>
      <Table borderStyle={{borderWidth: 2, borderColor: '#c8e1ff'}}>
        <Row data={tableHead} style={styles.head} textStyle={styles.textHeader}/>
        {
          contactos.map((rowData, index) => (
            <th key={index} style={styles.row}>
              {
                rowData.map((cellData, cellIndex) => (
                  <th key={cellIndex} data={cellData} textStyle={styles.textData}/>
                ))
              }
            </th>
          ))
        }
      </Table>
    </View>
  )
}


const styles = StyleSheet.create({
  head: { height: 40, backgroundColor: '#808B97' },
  textHeader: { margin: 6, color: '#fff', textAlign: "center" },
  textData: { margin: 6, textAlign: "center" },
  row: { flexDirection: 'row'},
});