import { Figure, Image, Modal, Row } from '@themesberg/react-bootstrap';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Calificaciones from './Calificaciones';

const ModalCalificaciones = ({handleCloseModal, showModal, data, selects, construccionesType}) => {

    const [codigo, setCodigo] = useState('');
    const [images, setImages] = useState([]);

    useEffect(() => {

        let resImages = []

        if(data.imagenes !== undefined) {
            data.imagenes.forEach(res => {
                res.forEach(r => {
                    resImages.push(r)
                })
            })
            setImages(resImages)
        }

        const arr = data.destino.split(".")
        setCodigo(arr[1])
    }, []);

    var validateClick = (event) => {   
        var validate = event.target.classList.contains('hover-zoom2');
  
        if( !validate ) {
          event.target.classList.remove('normal');
          event.target.classList.add('hover-zoom2');
        } else {
          event.target.classList.add('normal');
          event.target.classList.remove('hover-zoom2');
        }
      };

    const nameConstruccionTipo = (id) => {
        if(selects.anexo_tipo !== undefined) {
            const obj = selects.anexo_tipo.find(res => res.id == id)
            if(obj.nombre !== undefined) {
                const arrayName = obj.nombre.split(".");
                const arrName = arrayName[1].split("_")
                return arrName.join(" ")
            } else {
                return "N/A"
            }
        }
    }
    

  return (
      <>
      <Modal show={showModal} onHide={handleCloseModal} size='lg'>
        <Modal.Header closeButton>
            <Row md={1}>
                <Modal.Title>Calificaciones</Modal.Title>
                <p>ID Construcción: {data?.id_construccion}</p>
            </Row>
        </Modal.Header>
        <Modal.Body>
            {
                data.tipo_construccion == 'Convencional' ? (
                    <>
                    <p><strong>Observaciones:</strong></p>
                    <p>{data.observaciones}</p>
                    <Calificaciones data={data} construccionesTipo={construccionesType} />
                    </>
                ) : (
                    <>
                    {
                        data.id_undconstipo == 4 && (
                            <Row md={2}>
                                <span><strong>Código: </strong> {codigo}</span>
                                <span><strong>Tipificación: </strong> {data.id_anexotipo != null && data.id_anexotipo != undefined && data.id_anexotipo !== "" ? nameConstruccionTipo(data.id_anexotipo) : ""}</span>
                            </Row>
                        )
                    }
                    <p><strong>Observaciones:</strong></p>
                    <p>{data.observaciones}</p>
                    {
                        images.length === 0 ?
                        <p>Sin imágenes</p>
                        :
                        images.map((image) => (
                                <Image
                                    width={180}
                                    height={180}
                                    src={image.route}
                                    thumbnail
                                    fluid
                                    className='normal'
                                    onClick={(event) => 
                                        validateClick(event)}
                                />
                        ))
                    }
                    </>
                )
            }
        </Modal.Body>
      </Modal>
      </>
  );
};

export default ModalCalificaciones;
