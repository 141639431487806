export const bodyParamsQuery = (bodyParams, page = 0, sizePage = 0, listaBorradores = "") => {
    let query = [];
    if (!!page) { query.push(`page=${page}`) }
    if (!!sizePage) { query.push(`limit=${sizePage}`) }
    if (!!listaBorradores) { query.push(`listaBorrador=${listaBorradores}`) }


    for (const key in bodyParams) {
        if (!bodyParams[key]) {
            delete bodyParams[key];
        }
    }

    return [bodyParams, query]
}